import { SetState } from 'zustand'
import { MyState } from '@/store'

export interface LocationState {
  location: null | { latitude: number; longitude: number }
  setLocation: (location: { latitude: number; longitude: number }) => void
}

const createLocationState = (set: SetState<MyState>) => ({
  location: null,
  setLocation: (location: { latitude: number; longitude: number }) => {
    set(() => ({ location }))
  },
})

export default createLocationState
