import useStore from '@/lib/store'
import { useEffect, useState } from 'react'
import shallow from 'zustand/shallow'

export function useDarkMode(): [boolean, any, any] {
  const [darkMode, setDarkMode] = useStore(
    (state) => [state.mode, state.setDarkMode],
    shallow,
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Get from local storage by key
      const item = window?.localStorage.getItem('gn-color-scheme')
      // Parse stored json or if none return initialValue
      if (item) {
        const isDark = JSON.parse(item)
        setDarkMode(isDark)
        if (isDark) {
          document.documentElement.classList.add('dark')
        } else {
          document.documentElement.classList.remove('dark')
        }
      } else if (window.matchMedia('(prefers-color-scheme: dark)')) {
        setDarkMode(true)
        document.documentElement.classList.add('dark')
      }
    }
  }, [])

  const changeMode = (value: boolean) => {
    setDarkMode(value)
    window?.localStorage.setItem('gn-color-scheme', JSON.stringify(value))
    if (value) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  const useSystem = () => {
    if (window.matchMedia('(prefers-color-scheme: dark)')) {
      setDarkMode(true)
      document.documentElement.classList.add('dark')
      window?.localStorage.setItem('gn-color-scheme', JSON.stringify(true))
    } else {
      setDarkMode(false)
      document.documentElement.classList.remove('dark')
      window?.localStorage.setItem('gn-color-scheme', JSON.stringify(false))
    }
  }

  return [darkMode, changeMode, useSystem]
}
