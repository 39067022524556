import { Fragment } from 'react'
import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import { useController } from 'react-hook-form'
import { AnimatePresence, motion } from 'framer-motion'

interface RadioGroupProps {
  label: string
  className?: string
  description?: string
  name: string
  options: {
    name: string | number
    description?: string
    label: string
    icon?: React.ReactNode
  }[]
  required?: boolean
  error?: string
  value?: any
  disabled?: boolean
  onChange?: (event) => void
}

export const RadioGroupInput = ({
  label = '',
  className = '',
  options = [],
  error = '',
  value = null,
  required = false,
  name = '',
  disabled = false,
  onChange = () => null,
}: RadioGroupProps) => {
  const handleChange = (data: any) => {
    const option = options.find((option) => option.name.toString() === data.name.toString())
    onChange(option.name)
  }

  return (
    <div className={clsx('w-full space-y-4', className)}>
      <RadioGroup
        value={options?.find((option) => option.name.toString() === value?.toString())}
        onChange={handleChange}
        disabled={disabled}
      >
        <RadioGroup.Label
          className={clsx(
            'block font-serif font-bold leading-normal text-base',
            error.length ? 'text-red-500' : 'text-black dark:text-white',
          )}
        >
          {label}
          {required ? (
            <>
              <span className="font-bold text-red-500 inline-block ml-1">*</span>
              <span className="sr-only">Required</span>
            </>
          ) : null}
        </RadioGroup.Label>
        <div
          className={clsx(
            'mt-4 flex flex-wrap items-start gap-3 sm:gap-4',
            disabled ? 'opacity-50 pointer-events-none' : '',
          )}
        >
          {[...options].map((option, optionIdx) => (
            <Fragment key={option.label}>
              <RadioGroup.Option key={option.label} value={option} className="relative">
                {({ active, checked }) => (
                  <>
                    <div
                      className={clsx(
                        'cursor-pointer z-10 relative flex sm:items-center flex-col px-4 py-2 sm:py-3 sm:px-5  text-white focus:outline focus:outline-brand  focus:outline-2 focus:outline-offset-4 focus:ring-transparent  border-2 text-sm font-bold leading-normal align-bottom rounded-md select-none transition-all ease-out duration-250  min-w-5 min-h-5 ',
                        checked
                          ? 'bg-gradient-to-tr from-brand to-brand-400 border-brand-700 dark:border-brand-400  shadow-lg  shadow-brand/40'
                          : 'border-brand/20 dark:border-brand/40  dark:bg-zinc-800 bg-white   shadow-subtler   hover:shadow-lg hover:bg-brand/20 hover:border-brand hover:dark:border-brand',
                      )}
                    >
                      <div className="flex-1 flex justify-start">
                        <div className="flex flex-col items-start">
                          <div className={clsx(option.icon && 'flex items-center space-x-4 ')}>
                            {option.icon ? (
                              <span
                                className={clsx(
                                  checked ? 'stroke-white fill-white' : 'stroke-brand-500 fill-brand-500',
                                )}
                              >
                                {option.icon}
                              </span>
                            ) : null}
                            <RadioGroup.Label
                              as="span"
                              className={clsx(
                                'block font-bold text-base   ',
                                checked ? 'text-white' : 'text-zinc-900 dark:text-white',
                              )}
                            >
                              {option.label}
                            </RadioGroup.Label>
                          </div>
                          {option.description && (
                            <RadioGroup.Description
                              as="span"
                              className={clsx(checked ? 'text-brand-700' : 'text-zinc-500', 'block text-sm')}
                            >
                              <span>{option.description}</span> <span aria-hidden="true">&middot;</span>{' '}
                            </RadioGroup.Description>
                          )}
                        </div>
                      </div>
                    </div>
                    {checked ? (
                      <AnimatePresence>
                        <motion.div
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          exit={{ scale: 0 }}
                          transition={{ duration: 0.2, type: 'spring' }}
                          className="absolute -top-3 -left-3 rounded-full bg-emerald-500 text-white p-1 z-10 shadow-lg shadow-zinc-900/80"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 drop-shadow-xl"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </motion.div>
                      </AnimatePresence>
                    ) : null}
                  </>
                )}
              </RadioGroup.Option>
            </Fragment>
          ))}
        </div>
      </RadioGroup>
    </div>
  )
}

RadioGroupInput.displayName = 'RadioGroupInput'

interface ControlledRadioGroupProps extends RadioGroupProps {
  control?: any
  rules?: any
  name: string
  disabled?: boolean
}

export const ControlledRadioGroupInput = ({
  label,
  control,
  name,
  className,
  rules,
  options = [],
  description,
  disabled = false,
}: ControlledRadioGroupProps) => {
  const {
    field: { onChange, name: inputName, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: '',
  })

  return (
    <RadioGroupInput
      name={inputName}
      onChange={onChange}
      label={label}
      disabled={disabled}
      description={description}
      options={options}
      required={rules?.required}
      value={value}
      error={error?.type}
      className={className}
    />
  )
}
