import React from 'react'
import clsx from 'clsx'
import { useController } from 'react-hook-form'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { Label } from '@/components'

interface InputProps {
  label: string
  hideLabel?: boolean
  type?: 'text' | 'email' | 'password' | 'search' | 'tel'
  pattern?: string
  name: string
  value: any
  className?: string
  description?: string
  required?: boolean
  error?: string
  placeholder?: string
  disabled?: boolean
  center?: boolean
  small?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Input: React.FC<InputProps> = ({
  label,
  name = '',
  type = 'text',
  value = '',
  description,
  error = '',
  pattern = '',
  placeholder = '',
  required = false,
  hideLabel = false,
  disabled = false,
  center = false,
  small = false,
  className,
  onChange = () => null,
}: InputProps) => {
  return (
    <div
      className={clsx(
        ' relative',
        disabled ? 'opacity-50 saturate-50 blur-[2px] select-none pointer-events-none' : '',
        className,
      )}
    >
      <Label
        name={name}
        description={description}
        error={error}
        label={label}
        required={required}
        className={clsx('mb-2', hideLabel && 'sr-only')}
      />

      <div className="relative">
        <input
          id={name}
          onChange={onChange}
          onBlur={onChange}
          type={type}
          name={name}
          value={value}
          inputMode={type === 'tel' ? 'numeric' : 'text'}
          pattern={pattern ? pattern : null}
          placeholder={placeholder}
          aria-invalid={!!error}
          aria-disabled={disabled}
          disabled={disabled}
          aria-describedby={`${name}-description`}
          className={clsx(
            ' border-2 transition   border-zinc-200 dark:border-zinc-700/60  dark:bg-zinc-800 bg-white  font-sans appearance-none block w-full  rounded-lg    placeholder-zinc-400 dark:placeholder-zinc-400/80   peer relative z-[1] font-medium  selection:bg-black text-black dark:text-white focus:ring-1 focus:ring-offset-1 placeholder:font-serif placeholder:font-regular  focus:outline focus:outline-brand  focus:outline-2 focus:outline-offset-0 focus:ring-transparent  text-base caret-brand  peer shadow-subtler dark:shadow-lg dark:shadow-zinc-800/70',
            center ? 'text-center' : '',
            small ? 'p-2 text-base' : 'p-3 text-base',
            error ? 'border-red-600 focus:ring-rose-600 focus:border-rose-600 text-rose-600' : '',
            disabled ? 'bg-zinc-100' : '',
          )}
        />

        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
          </div>
        )}
      </div>

      {error && (
        <p className="mt-1 text-sm text-rose-600" id={`${name}-description`}>
          {error}
        </p>
      )}
    </div>
  )
}

Input.displayName = 'Input'

interface ControlledInputProps {
  label: string
  type?: 'text' | 'email' | 'password' | 'search' | 'tel'
  name: string
  rules?: any
  className?: string
  pattern?: string
  control?: any
  placeholder?: string
  description?: string
  disabled?: boolean
  center?: boolean
  small?: boolean
  hideLabel?: boolean
}

export const ControlledInput = ({
  label,
  control,
  name,
  rules,
  className,
  type,
  placeholder,
  description,
  pattern = '',
  disabled = false,
  center = false,
  small = false,
  hideLabel = false,
}: ControlledInputProps) => {
  const {
    field: { onChange, name: inputName, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: '',
  })

  return (
    <Input
      description={description}
      placeholder={placeholder}
      type={type}
      className={className}
      label={label}
      onChange={onChange}
      value={value}
      hideLabel={hideLabel}
      required={rules?.required}
      pattern={pattern}
      name={inputName}
      small={small}
      center={center}
      disabled={disabled}
      error={error?.message || error?.type === 'required' ? 'Required' : ''}
    />
  )
}
