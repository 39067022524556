import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { SWRConfig } from 'swr'
import { SessionProvider, useSession } from 'next-auth/react'
import * as Fathom from 'fathom-client'
import shallow from 'zustand/shallow'
import useStore from '@/lib/store'
import { ApolloProvider } from '@apollo/client'
import { Toaster } from 'react-hot-toast'

import apolloClient from '@/lib/apollo'

import { Notification, CookieBanner, SearchBox, PageLoading, Footer, ErrorBoundary, Loading } from '@/components'
import fetchJson from '@/lib/fetcher'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import '../styles/global.css'

import { AnimatePresence } from 'framer-motion'
import Nav from '@/components/Nav'

function GNApp({ Component, pageProps: { session, ...pageProps }, err }: any) {
  const router = useRouter()
  const [startLoading, endLoading] = useStore((state) => [state.startLoading, state.endLoading], shallow)

  useEffect(() => {
    // Initialize Fathom when the app loads
    // Example: yourdomain.com
    //  - Do not include https://
    //  - This must be an exact match of your domain.
    //  - If you're using www. for your domain, make sure you include that here.
    Fathom.load('PLNULETS', {
      includedDomains: ['goodnbr.co'],
    })

    function onRouteChangeComplete() {
      Fathom.trackPageview()
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [router.events])

  useEffect(() => {
    router.events.on('routeChangeStart', startLoading)
    router.events.on('routeChangeComplete', endLoading)
    router.events.on('routeChangeError', endLoading)

    return () => {
      router.events.off('routeChangeStart', startLoading)
      router.events.off('routeChangeComplete', endLoading)
      router.events.off('routeChangeError', endLoading)
    }
  }, [router, startLoading, endLoading])

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <SessionProvider session={session}>
          <SWRConfig
            value={{
              fetcher: fetchJson,
              onError: (err) => {
                console.error(err)
              },
            }}
          >
            <Toaster position="bottom-center" />
            <Nav />
            <AnimatePresence exitBeforeEnter initial={false} onExitComplete={() => window.scrollTo(0, 0)}>
              <Component {...pageProps} err={err} />
            </AnimatePresence>
            <Footer />
            <Notification />
            <SearchBox />
            <CookieBanner />
            <PageLoading />
          </SWRConfig>
        </SessionProvider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}

export default GNApp
