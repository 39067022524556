import { SetState } from 'zustand'
import { MyState } from '@/store'

export interface NotificationState {
  notificationTitle: string
  notificationMessage: string
  notificationType: string
  notificationActive: boolean
  clearable: boolean | null
  setNotification: (title: string, message: string, type: 'success' | 'error' | 'warning', clearable?: boolean) => void
  resetNotification: () => void
  clearNotification: () => void
}

const createNotificationState = (set: SetState<MyState>) => ({
  notificationTitle: '',
  notificationMessage: '',
  notificationType: 'success',
  notificationActive: false,
  clearable: false,
  setNotification: (title: string, message: string, type: 'success' | 'error' | 'warning', clearable?: boolean) => {
    set(() => ({
      notificationTitle: title,
      notificationMessage: message,
      notificationType: type,
      notificationActive: true,
      clearable,
    }))
  },
  resetNotification: () => {
    set(() => ({
      notificationTitle: '',
      notificationMessage: '',
      notificationType: '',
      notificationActive: false,
      clearable: null,
    }))
  },
  clearNotification: () => {
    set(() => ({
      notificationActive: false,
    }))
  },
})

export default createNotificationState
