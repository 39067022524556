import { useMemo, useState } from 'react'
import ReactMapGL, { NavigationControl, Marker, Popup } from 'react-map-gl'
import clsx from 'clsx'
import { FaStethoscope, FaTree, FaPills, FaTools, FaGlasses, FaBook } from 'react-icons/fa'
import { MdLocalGroceryStore, MdPets, MdLocalLaundryService, MdSchool } from 'react-icons/md'
import { IoMdPin } from 'react-icons/io'
import { AiFillBank } from 'react-icons/ai'
import { BiHomeHeart } from 'react-icons/bi'
import find from 'lodash.find'
import { Title } from '@/components'

function formatType(type: string) {
  switch (type) {
    case 'grocery' || 'discountstore':
      return <MdLocalGroceryStore size="14" />
    case 'chiropractors' ||
      'acupunture' ||
      'health' ||
      'physicaltherapy' ||
      'generaldentistry' ||
      'lifecoach' ||
      'c_and_mh' ||
      'surgeons' ||
      'drugstores' ||
      'oralsurgeons':
      return <FaStethoscope size="14" />
    case 'pharmacy':
      return <FaPills size="14" />
    case 'vet':
      return <MdPets size="14" />
    case 'dryclean' || 'laundry' || 'laundryservices' || 'sewingalterations':
      return <MdLocalLaundryService size="14" />
    case 'homedecor' || 'hardware' || 'locksmiths':
      return <FaTools size="14" />
    case 'galleries':
      return <BiHomeHeart size="14" />
    case 'daycamps' || 'parks' || 'recreation':
      return <FaTree size="14" />
    case 'libraries' || 'childcare' || 'privatetutors':
      return <FaBook size="14" />
    case 'banks':
      return <AiFillBank size="14" />
    case 'elementaryschools' || 'highschools' || 'montessori' || 'preschools' || 'religiousschools':
      return <MdSchool size="14" />
    case 'optometrists':
      return <FaGlasses size="14" />
    default:
      return <IoMdPin size="14" />
  }
}

const MAPBOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN

const navControlStyle = {
  right: 10,
  bottom: 40,
}

interface HoodMapProps {
  lat: number
  lng: number
  zoom: number

  showUserPin?: boolean
  businesses: any
  reviews: any[]
  poi: any[]
}

export const HoodMap: React.FC<HoodMapProps> = ({
  lat,
  lng,
  zoom,
  businesses,
  reviews,
  poi,
  showUserPin = false,
}: HoodMapProps) => {
  const [categories, setCategories] = useState<any[]>([])
  const [popInfo, setPopInfo] = useState<any | null>(null)

  const updateValues = (data) => {
    if (categories.includes(data)) {
      const filteredArray = categories.filter((category) => category !== data)
      setCategories(filteredArray)
    } else {
      setCategories([...categories, data])
    }
  }

  const businessProperties = useMemo(
    () =>
      businesses.businesses.reduce((list, business) => {
        return find(list, { alias: business.categories[0].alias, name: business.categories[0].title })
          ? list
          : [...list, { alias: business.categories[0].alias, name: business.categories[0].title }]
      }, []),
    [businesses],
  )

  const renderPopup = () => (
    <Popup
      anchor="bottom"
      longitude={popInfo.coordinates.longitude}
      latitude={popInfo.coordinates.latitude}
      closeOnClick={false}
      onClose={() => setPopInfo(null)}
    >
      <div>
        <a href={popInfo.url} className="no-underline font-sans text-zinc-900">
          <p>{popInfo.categories[0].title}</p>
          <p>{popInfo.name}</p>
        </a>
      </div>
    </Popup>
  )

  return (
    <div className="w-full grid grid-cols-12 gap-10">
      <figure className="block relative rounded-md shadow-subtle overflow-hidden col-span-8 h-[600px]">
        <ReactMapGL
          latitude={businesses?.region?.center?.latitude || lat}
          longitude={businesses?.region?.center?.longitude || lng}
          zoom={13}
          style={{ width: '100%', height: '600px' }}
          reuseMaps={true}
          pitch={35}
          mapStyle={`mapbox://styles/mapbox/outdoors-v11?optimize=true`}
          mapboxAccessToken={MAPBOX_TOKEN}
          attributionControl={false}
        >
          {businesses.businesses
            ? businesses.businesses
                .filter((biz) => (categories.length ? categories.includes(biz.categories[0].alias) : biz))
                .map((business) => (
                  <Marker
                    latitude={business.coordinates.latitude}
                    longitude={business.coordinates.longitude}
                    key={business.id}
                  >
                    <button
                      className={clsx(
                        'rounded-full flex items-center justify-center shadow-lg',
                        business.categories[0].alias
                          ? 'bg-white border border-smoke-300 w-8 h-8 text-zinc-900 font-sans'
                          : 'bg-gradient-to-b from-blue-500 to-blue-600 w-4 h-4 text-white',
                      )}
                      onClick={() => setPopInfo(business)}
                      title={business.name}
                    >
                      {business.categories[0].alias ? formatType(business.categories[0].alias) : null}
                    </button>
                  </Marker>
                ))
            : null}
          {popInfo && renderPopup()}
        </ReactMapGL>
      </figure>
      <div className="col-span-4">
        <Title>Categories</Title>
        <form className="flex justify-start content-start flex-wrap mt-4">
          {businessProperties.map((businessValues) => (
            <label
              htmlFor={businessValues.alias}
              className="block relative mr-2 mb-2 md:mr-2 md:mb-3"
              key={businessValues.alias}
            >
              <input
                type="checkbox"
                id={businessValues.alias}
                className="peer appearance-none h-0 absolute"
                checked={!!categories.filter((category) => category === businessValues.alias)[0]}
                onChange={() => updateValues(businessValues.alias)}
              />
              <span className="cursor-pointer z-10 relative sm:items-center flex-col px-3.5 py-2 sm:py-3 sm:px-5 border-transparent focus-visible:outline-none  focus:outline-none focus:ring-2 focus:ring-brand-500 focus-within:ring-brand-500  border leading-normal align-bottom rounded-full select-none transition-all ease-out duration-250 shadow-subtler hover:shadow-subtle border-zinc-300 dark:border-zinc-600 dark:bg-zinc-800/70 focus:dark:bg-zinc-800 bg-zinc-50 focus:bg-zinc-50 text-zinc-600 dark:text-zinc-200 block font-semibold sm:text-base text-sm">
                {businessValues.name}
              </span>
              <span
                className={
                  'peer-checked:block peer-checked:after:-inset-y-[3px] peer-checked:after:-inset-x-[3px] hidden after:inset-0 after:content-[""] after:absolute  after:bg-gradient-to-r after:from-brand-700 after:via-teal-400 after:to-yellow-500 after:dark:to-amber-600 after:rounded-full  -z-[1] after:pointer-events-none'
                }
              />
            </label>
          ))}
          <button
            type="button"
            className="appearance-none mr-3 mb-3 py-2 rounded-md text-xs text-smoke-700 font-oakes"
            onClick={() => setCategories([])}
          >
            Clear Filters
          </button>
        </form>
      </div>
    </div>
  )
}

HoodMap.displayName = 'HoodMap'
