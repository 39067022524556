import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { XIcon } from '@heroicons/react/outline'
import { useForm } from 'react-hook-form'
import { ControlledRadioGroupInput, ControlledToggle, Button, ControlledNumberInput } from '@/components'
import { superstructResolver } from '@hookform/resolvers/superstruct'
import { object, string, number, boolean, union, nullable } from 'superstruct'

const schema = object({
  rentLow: number(),
  rentHigh: number(),
  accessibility: union([boolean(), string()]),
  bathrooms: nullable(number()),
  bedrooms: nullable(number()),
  laundry: union([boolean(), string()]),
  listingType: nullable(string()),
  parkingAvailable: union([boolean(), string()]),
  utilitiesIncluded: union([boolean(), string()]),
  airConditioning: union([boolean(), string()]),
  dishwasher: union([boolean(), string()]),
  petsWelcome: union([boolean(), string()]),
})

export const FilterModal = ({
  open,
  setOpen,
  handleFilterUpdates,
}: {
  open: boolean
  setOpen: (val: boolean) => any
  handleFilterUpdates: (val) => any
}) => {
  const [searchCount, setSearchCount] = useState(0)
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<any>({
    resolver: superstructResolver(schema),
    defaultValues: {
      rentLow: 10,
      rentHigh: 10000,
      accessibility: false,
      bathrooms: null,
      bedrooms: null,
      laundry: false,
      listingType: null,
      parkingAvailable: false,
      utilitiesIncluded: false,
      airConditioning: false,
      dishwasher: false,
      petsWelcome: false,
    },
  })
  const watchAllFields = watch()

  useEffect(() => {
    const facets = Object.entries(watchAllFields)
    const count = facets.reduce((a, facet) => {
      if (facet[0] === 'rentLow' && facet[1] > 10) {
        return a + 1
      } else if (facet[0] === 'rentHigh' && facet[1] < 10000) {
        return a + 1
      } else if (facet[1] !== '' && facet[1] && facet[0] !== 'rentLow' && facet[0] !== 'rentHigh') {
        return a + 1
      } else {
        return a
      }
    }, 0)
    setSearchCount(count)
  }, [watchAllFields])

  const handleSubmitFn = (data) => {
    const filtersToApply = Object.entries(data)
      .filter((facet) => facet[1])
      .filter((facet) => {
        if (facet[0] === 'rentLow') {
          if (facet[1] > 10) {
            return facet
          }
        } else if (facet[0] === 'rentHigh') {
          if (facet[1] < 10000) {
            return facet
          }
        } else {
          return facet
        }
      })
    const filters = {}
    filtersToApply.forEach((filter) => {
      filters[filter[0]] = filter[1]
    })
    console.log(filters)
    handleFilterUpdates(filters)
    setOpen(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-[35]" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 dark:bg-zinc-800 bg-zinc-600 dark:bg-opacity-50 backdrop-blur-sm  bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <div className="fixed bottom-0 top-14 right-0 sm:pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-xl">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 sm:left-0 right-2 pt-4 pr-2 flex sm:-ml-16 sm:pr-4 z-10">
                    <button
                      type="button"
                      className="rounded-md text-zinc-300 dark:text-zinc-900 hover:text-zinc-500  bg-zinc-900 dark:bg-white shadow-xl p-1 sm:p-3 focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col pt-6 pb-16 dark:bg-[#111] bg-snow shadow-xl overflow-y-scroll">
                  <div className="px-6 flex items-start">
                    <Dialog.Title className="text-3xl font-sans  font-extrabold text-zinc-700 dark:text-zinc-200 tracking-tighter">
                      Search Filters
                    </Dialog.Title>
                    {searchCount ? (
                      <div className="bg-brand dark:bg-brand rounded-full p-3 py-1 flex items-center mt-2 ml-3">
                        <span className="sr-only">Filters</span>
                        <span className="text-sm font-semibold text-white ">{searchCount}</span>
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-4 relative flex-1 px-6">
                    {/* Replace with your content */}
                    <form
                      onSubmit={handleSubmit(handleSubmitFn)}
                      className="focus:outline-none space-y-6 pb-24 relative divide-y divide-zinc-200 dark:divide-zinc-800"
                    >
                      <div className="flex space-x-6 pt-6">
                        <ControlledNumberInput control={control} name="rentLow" label="Minimum Rent" min={10} />
                        <ControlledNumberInput control={control} name="rentHigh" label="Maximum Rent" max={10000} />
                      </div>

                      <div className="pt-6">
                        <ControlledToggle
                          control={control}
                          name="utilitiesIncluded"
                          label="Utilities Included"
                          reverse
                        />
                      </div>
                      <div className="pt-6">
                        <ControlledRadioGroupInput
                          name="bedrooms"
                          control={control}
                          label="Bedrooms"
                          options={[
                            { name: 0, label: 'Bachelor', description: '' },
                            { name: 1, label: '1', description: '' },
                            { name: 2, label: '2', description: '' },
                            { name: 3, label: '3', description: '' },
                            { name: 50, label: '3+', description: '' },
                          ]}
                        />
                      </div>
                      <div className="pt-6">
                        <ControlledRadioGroupInput
                          name="bathrooms"
                          control={control}
                          label="Bathrooms"
                          options={[
                            { name: 0, label: '0', description: '' },
                            { name: 1, label: '1', description: '' },
                            { name: 2, label: '2', description: '' },
                            { name: 3, label: '3', description: '' },
                            { name: 50, label: '3+', description: '' },
                          ]}
                        />
                      </div>
                      <div className="pt-6">
                        <ControlledRadioGroupInput
                          control={control}
                          name="listingType"
                          label="Type of Building"
                          options={[
                            { name: 'house', label: 'House', description: '' },
                            { name: 'condo', label: 'Condo', description: '' },
                            { name: 'room', label: 'Room', description: '' },
                            { name: 'apartment', label: 'Apartment', description: '' },
                          ]}
                        />
                      </div>
                      <div className="pt-6">
                        <ControlledToggle
                          control={control}
                          name="accessibility"
                          label="Accessibility Friendly"
                          reverse
                        />
                      </div>
                      <div className="pt-6">
                        <ControlledToggle control={control} name="parkingAvailable" label="Parking Available" reverse />
                      </div>
                      <div className="pt-6">
                        <ControlledToggle control={control} name="dishwasher" label="Dishwasher" reverse />
                      </div>
                      <div className="pt-6">
                        <ControlledToggle control={control} name="airConditioning" label="Air Conditioning" reverse />
                      </div>
                      <div className="pt-6">
                        <ControlledToggle control={control} name="laundry" label="Laundry Available" reverse />
                      </div>
                      <div className="pt-6">
                        <ControlledToggle control={control} name="petsWelcome" label="Pets Welcome" reverse />
                      </div>
                      <div className="max-w-xl w-full fixed bottom-0 pb-6 pt-4 px-6 right-0 z-10 dark:bg-hunter bg-sand flex flex-col items-center space-y-3">
                        <Button type="submit" className="w-full items-center" alt>
                          Apply Filters
                        </Button>
                        <button
                          type="button"
                          onClick={() => reset()}
                          className="text-base font-medium underline tracking-tight underline-offset-4"
                          title="Clear Filters"
                        >
                          Clear Filters
                        </button>
                      </div>
                    </form>
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
