import { format, parseJSON } from 'date-fns'
import { useSession } from 'next-auth/react'
import { Button, Card, Title } from '@/components'
import { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { BOOK_SESSION_SHOWING, GET_SHOWINGS_FOR_RENTAL, UNBOOK_SESSION_SHOWING } from '@/queries'

export const Showings = ({ rentalId }: any) => {
  const { data: session } = useSession()

  const { data: listing } = useQuery(GET_SHOWINGS_FOR_RENTAL, {
    variables: {
      id: rentalId,
    },
  })

  const [bookSession, { data: bookSessionData, loading: bookSessionLoading, error: bookSessionError }] =
    useMutation(BOOK_SESSION_SHOWING)
  const [unbookSession, { data: unbookSessionData, loading: unbookSessionLoading, error: unbookSessionError }] =
    useMutation(UNBOOK_SESSION_SHOWING)

  const [userHasBooked, setUserHasBooked] = useState(false)

  useEffect(() => {
    if (listing && session) {
      setUserHasBooked(listing.showingsForRental.filter((showing) => showing.userId === session.user.id).length)
    }
  }, [listing, session, setUserHasBooked])

  const bookShowing = async (showingid: string) => {
    try {
      bookSession({
        variables: {
          rentalId,
          showingTimeId: showingid,
        },
        refetchQueries: [
          {
            query: GET_SHOWINGS_FOR_RENTAL,
            variables: {
              id: rentalId,
            },
          },
        ],
      })
    } catch (err) {}
  }

  const unbookShowing = async (showingid: string) => {
    try {
      unbookSession({
        variables: {
          rentalId,
          showingTimeId: showingid,
        },
        refetchQueries: [
          {
            query: GET_SHOWINGS_FOR_RENTAL,
            variables: {
              id: rentalId,
            },
          },
        ],
      })
    } catch (err) {}
  }

  if (!listing) {
    return null
  }

  const content = listing?.data
  return (
    <div>
      <header className="pb-6 sm:pb-4 pt-6">
        <Title as="h1" size="md">
          Book a showing
        </Title>
      </header>
      <div className="">
        <div className="scroll-my-24">
          <ol className="space-y-6">
            {content.showings.map((showing) => (
              <li key={showing.id} className="">
                <Card>
                  <div className="flex items-center justify-between">
                    <p className="text-zinc-800 dark:text-zinc-200 font-semibold tracking-tight text-lg">
                      {format(parseJSON(showing.date), 'MMMM do, yyyy - K:mma')}
                    </p>
                    {showing.userId ? (
                      showing.userId === session?.user.id ? (
                        <Button small outline onClick={() => unbookShowing(showing.id)}>
                          Unbook
                        </Button>
                      ) : (
                        <p className="font-semibold text-brand-500 dark:text-brand-300 text-sm">Booked</p>
                      )
                    ) : session ? (
                      !userHasBooked ? (
                        <Button small outline onClick={() => bookShowing(showing.id)}>
                          Book
                        </Button>
                      ) : null
                    ) : (
                      <Button outline small internal url={`/auth/login?redirect=listing/${content.listing.id}`}>
                        Login to book a showing
                      </Button>
                    )}
                  </div>
                </Card>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  )
}
