import clsx from 'clsx'
import { format, parseJSON } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import { Title, Card, ButtonLink, Button, AlertModal } from '@/components'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { DELETE_COMMENT, NEW_COMMENT, GET_COMMENTS_FOR_REVIEW } from '@/queries'
import { useMutation, useQuery } from '@apollo/client'

export const Comments = ({
  reviewId = '',
  userId,
}: {
  comments: any[]
  reviewId: string
  listingId: string
  userId: string | null
}) => {
  const [commentsOpen, setCommentsOpen] = useState(true)
  const [commentToDelete, setCommentToDelete] = useState<any>()
  const { data, loading, error } = useQuery(GET_COMMENTS_FOR_REVIEW, {
    variables: {
      id: reviewId,
    },
  })
  const [deleteCommentById] = useMutation(DELETE_COMMENT)
  const [addComment, { loading: newCommentLoading }] = useMutation(NEW_COMMENT)

  const { handleSubmit, register, reset } = useForm()

  const postComment = async (data) => {
    try {
      addComment({
        variables: {
          content: data.comment,
          reviewId,
        },
        refetchQueries: [
          {
            query: GET_COMMENTS_FOR_REVIEW,
            variables: {
              id: reviewId,
            },
          },
        ],
      })
      reset()
    } catch (err) {
      console.log(err)
    }
  }

  const deleteCommentRequest = async () => {
    try {
      deleteCommentById({
        variables: { commentId: commentToDelete },
        refetchQueries: [
          {
            query: GET_COMMENTS_FOR_REVIEW,
            variables: {
              id: reviewId,
            },
          },
        ],
      })
      setCommentToDelete(null)
    } catch (err) {
      console.log(err)
    }
  }

  if (loading) {
    return null
  }

  return (
    <div className="w-full max-w-2xl mt-8 mb-2 relative">
      <div className="flex space-x-2 items-center">
        <Title as="h3" size="md">
          Comments
        </Title>
        <button
          onClick={() => setCommentsOpen(!commentsOpen)}
          className="cursor-pointer rounded-full p-1.5 bg-zinc-200 dark:bg-zinc-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={clsx('h-8 w-8', commentsOpen ? 'rotate-90' : '')}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
          </svg>
          <span className="sr-only">Toggle Comments</span>
        </button>
      </div>

      <AnimatePresence initial={false}>
        {commentsOpen ? (
          <motion.div
            initial={{ opacity: 0, height: 0, y: '-5px' }}
            animate={{ opacity: 1, height: 'auto', y: 0 }}
            exit={{ opacity: 0, height: 0, y: '-5px' }}
            transition={{ duration: 0.3, ease: 'easeIn' }}
          >
            <Card className="mt-4">
              <ul className="">
                {data.getCommentsForReview.map((comment, commentIdx) => (
                  <li
                    key={comment.id}
                    className={clsx(
                      commentIdx === 0 ? '' : 'border-t border-zinc-200 dark:border-zinc-700',
                      'flex-1 py-6',
                    )}
                  >
                    <p className="text-sm text-zinc-700 dark:text-zinc-200 tracking-tight font-semibold">
                      <time dateTime={format(parseJSON(comment.createdAt), 'MMM do, yyyy')}>
                        {format(parseJSON(comment.createdAt), 'MMM do, yyyy')}
                      </time>
                    </p>
                    <div
                      className="mt prose max-w-none text-zinc-700 dark:text-zinc-200 font-medium"
                      dangerouslySetInnerHTML={{ __html: comment.content }}
                    />
                    <div>
                      {userId === comment.userId ? (
                        <ButtonLink small onClick={() => setCommentToDelete(comment.id)}>
                          Delete
                        </ButtonLink>
                      ) : null}
                    </div>
                  </li>
                ))}
              </ul>
              <form onSubmit={handleSubmit((data) => postComment(data))} className="my-4">
                <div className="relative w-full mb-4">
                  <label htmlFor="comment" className="sr-only">
                    Comment
                  </label>
                  <textarea
                    {...register('comment')}
                    rows={2}
                    name="comment"
                    id="comment"
                    required
                    className={clsx(
                      'border transition   dark:bg-zinc-800  bg-sand-200  font-sans appearance-none block w-full  rounded-md  focus:outline-none placeholder-zinc-400 focus:ring-1  peer relative z-[1] shadow-input font-medium selection:bg-brand-100 selection:dark:bg-brand-400 p-3 text-base caret-brand-500 text-zinc-800 dark:text-white focus:dark:border-transparent focus:border-transparent focus:ring-transparent border-zinc-300 dark:border-zinc-600 disabled:opacity-50',
                      !userId && '!opacity-20',
                    )}
                    placeholder="Write a comment..."
                    defaultValue={''}
                    disabled={!userId}
                  />
                  <span className='hidden peer-focus:block after:content-[""] after:absolute after:-inset-[3px] after:bg-gradient-to-r after:from-holly-300 after:dark:from-holly-300  after:to-brand-500 after:dark:to-brand-500 after:rounded-lg -z-[1] after:pointer-events-none' />
                  {!userId ? (
                    <p className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 dark:text-zinc-50 text-zinc-500 tracking-tight text-sm font-semibold ">
                      Log in to post comment
                    </p>
                  ) : null}
                </div>
                <div className="flex justify-end items-center">
                  {loading ? (
                    <div className="mr-4 text-center">
                      <svg
                        role="status"
                        className="inline w-5 h-5 text-zinc-200 animate-spin dark:text-zinc-700 fill-brand-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                  ) : null}
                  <Button small type="submit" disabled={!userId}>
                    Post
                  </Button>
                </div>
              </form>
            </Card>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {commentToDelete ? (
          <AlertModal
            type="error"
            title="Are you sure you want to delete this comment?"
            description=""
            successMessage="Delete"
            cancelMessage="Cancel"
            handleCancel={() => setCommentToDelete(null)}
            handleSuccess={deleteCommentRequest}
          />
        ) : null}
      </AnimatePresence>
    </div>
  )
}
