import { motion, AnimatePresence } from 'framer-motion'
import useStore from '@/store'

export const PageLoading = () => {
  const loading = useStore((state) => state.loading)

  return (
    <AnimatePresence>
      {loading && (
        <motion.div
          role="alert"
          aria-busy="true"
          aria-label="Loading"
          initial={{ opacity: 0, top: '-16px' }}
          animate={{ opacity: 1, top: '96px' }}
          exit={{ opacity: 0, top: '-16px' }}
          className="h-16 w-16 rounded-full bg-gradient-to-tr from-brand to-brand-400 fixed left-1/2 -translate-x-1/2  z-[999] shadow-lg shadow-brand/40 flex p-5"
        >
          <div className="loading-bars"></div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
