import React, { useState } from 'react'
import clsx from 'clsx'
import { useController } from 'react-hook-form'
import { Label } from '@/components'
import { StarIcon } from '@heroicons/react/solid'

interface RatingProps {
  value: number | string
  small?: boolean
  className?: string
}

export const Rating = ({ value = 4.5, small = false, className }: RatingProps) => {
  const ratingValue = Number(Number(value).toFixed()) - 1

  return (
    <div className={clsx('relative', className)}>
      <p className="sr-only">{value}</p>
      <ul className={clsx('flex', small ? 'space-x-0.5' : 'space-x-0.5')}>
        {Array.from(Array(5)).map((star, idx) => (
          <li key={`${star}-${idx}`} className="">
            <StarIcon
              className={clsx('text-black/20 dark:text-white', small ? 'w-3.5 h-3.5' : 'h-5 w-5')}
              aria-hidden="true"
            />
          </li>
        ))}
      </ul>
      <ul className={clsx('flex absolute top-0', small ? 'space-x-0.5' : 'space-x-0.5')}>
        {Array.from(Array(Number(ratingValue >= 0 ? ratingValue + 1 : 0)), (el, i) => (
          <li key={`${el}-${i}`}>
            <StarIcon className={clsx('text-yellow-500', small ? 'w-3.5 h-3.5' : 'h-5 w-5')} aria-hidden="true" />
          </li>
        ))}
      </ul>
    </div>
  )
}

interface RatingInputProps {
  label: string
  name: string
  value: number
  className?: string
  description?: string
  error?: string
  disabled?: boolean
  hideLabel?: boolean
  onChange: (event: any) => void
}

export const RatingInput = ({
  label,
  name = '',
  value = 4.5,
  description,
  error = '',
  disabled = false,
  onChange = () => null,
  hideLabel = false,
}: RatingInputProps) => {
  const [active, setActive] = useState(0)
  const [hovering, setHoveringActive] = useState(false)
  const ratingValue = Number(Number(value).toFixed())

  return (
    <div className="relative space-y-3">
      <Label
        name={name}
        description={description}
        error={error}
        label={label}
        className={clsx(hideLabel && 'sr-only')}
      />
      <input
        className="sr-only"
        type="number"
        value={value}
        onChange={onChange}
        disabled={disabled}
        aria-disabled={disabled}
      />
      <div className="relative">
        <ul className="flex space-x-1">
          {Array.from(Array(5)).map((star, idx) => (
            <li key={`${star}-${idx}`}>
              <StarIcon className="dark:text-white text-white h-10 w-10" aria-hidden="true" />
            </li>
          ))}
        </ul>
        <ul
          className="flex space-x-1 absolute top-0"
          onMouseOut={() => {
            setActive(0)
            setHoveringActive(false)
          }}
          onBlur={() => {
            setActive(0)
            setHoveringActive(false)
          }}
        >
          {Array.from(Array(5)).map((star, idx) => (
            <li key={`${star}-${idx}`}>
              <button
                onMouseOver={() => {
                  setHoveringActive(true)
                  setActive(idx + 1)
                }}
                onFocus={() => {
                  setHoveringActive(true)
                  setActive(idx + 1)
                }}
                onClick={() => onChange(idx + 1)}
                type="button"
                disabled={disabled}
              >
                <StarIcon
                  className={clsx(
                    'h-10 w-10',
                    disabled ? '' : 'hover:text-yellow-600 hover:dark:text-yellow-600 transition',
                    (active > idx && hovering && !disabled) || (ratingValue > idx && !hovering && !disabled)
                      ? 'dark:text-yellow-500 text-yellow-400'
                      : 'text-zinc-300 dark:text-zinc-200-700/10',
                  )}
                />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

RatingInput.displayName = 'RatingInput'

interface ControlledRatingInputProps {
  label: string
  name: string
  rules?: any
  className?: string
  control?: any
  disabled?: boolean
  description?: string
}

export const ControlledRatingInput = ({
  label,
  control,
  name,
  rules,
  className,
  description,
  disabled = false,
}: ControlledRatingInputProps) => {
  const {
    field: { onChange, name: inputName, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: '',
  })

  return (
    <RatingInput
      description={description}
      className={className}
      label={label}
      onChange={onChange}
      value={value}
      name={inputName}
      disabled={disabled}
      error={error?.message || error?.type === 'required' ? 'Required' : ''}
    />
  )
}
