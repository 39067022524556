import { motion } from 'framer-motion'
interface LayoutProps {
  children: React.ReactNode
  className?: string
}

const variants = {
  hidden: { opacity: 0, y: 0 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -100 },
}

export const Layout = ({ children, className = '' }: LayoutProps) => {
  return (
    <motion.div initial="hidden" animate="enter" exit="exit" variants={variants} transition={{ type: 'linear' }}>
      <main className={className}>{children}</main>
    </motion.div>
  )
}

Layout.displayName = 'Layout'
