import { useQuery } from '@apollo/client'
import { Title, ListingCard } from '@/components'
import { NEARBY_LISTINGS } from '@/queries'
import Skeleton from 'react-loading-skeleton'

export const Nearby = ({ id }: { id: string }) => {
  const { data, loading, error } = useQuery(NEARBY_LISTINGS, {
    variables: {
      nearbyListingsByIdId: id,
    },
  })

  return (
    <div className="mt-16">
      <Title as="h3" size="md">
        Nearby Properties
      </Title>
      {!error && (
        <ul className="mx-auto pb-4 divide-y-2 divide-zinc-300 dark:divide-zinc-700 relative z-10">
          {loading ? (
            <>
              <li className="relative py-4 sm:py-8">
                <div className="flex flex-col items-start col-span-12">
                  <span className="block w-full">
                    <Skeleton height="1.5rem" width="50%" />
                  </span>
                  <p className="dark:text-white text-black text-sm font-bold mt-0 w-full">
                    <Skeleton height="0.75rem" width="40%" />
                  </p>
                  <div className="col-span-12 mt-3 w-full">
                    <p className="dark:text-zinc-400 text-zinc-600 text-sm font-medium mb-1 prose truncate w-full">
                      <Skeleton count={2} height="0.75rem" width="100%" />
                    </p>
                    <div className="w-full">
                      <Skeleton height="0.75rem" width="40%" />

                      <p className="text-xs font-medium text-zinc-600 dark:text-zinc-400">
                        <Skeleton height="0.75rem" width="80%" />
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="relative py-4 sm:py-8">
                <div className="flex flex-col items-start col-span-12">
                  <span className="block w-full">
                    <Skeleton height="1.5rem" width="50%" />
                  </span>
                  <p className="dark:text-white text-black text-sm font-bold mt-0 w-full">
                    <Skeleton height="0.75rem" width="40%" />
                  </p>
                  <div className="col-span-12 mt-3 w-full">
                    <p className="dark:text-zinc-400 text-zinc-600 text-sm font-medium mb-1 prose truncate w-full">
                      <Skeleton count={2} height="0.75rem" width="100%" />
                    </p>
                    <div className="w-full">
                      <Skeleton height="0.75rem" width="40%" />

                      <p className="text-xs font-medium text-zinc-600 dark:text-zinc-400">
                        <Skeleton height="0.75rem" width="80%" />
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </>
          ) : (
            data?.nearbyListingsById.map((listing) => (
              <li key={listing.id} className="relative py-4 sm:py-8">
                <ListingCard listing={listing} />
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  )
}
