import { Title } from '@/components'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useQuery, gql } from '@apollo/client'

export const WalkScoreModule = ({ id }: any) => {
  const { data, loading, error } = useQuery(
    gql`
      query GetWalkScoreById($id: String!) {
        getWalkScoreById(id: $id) {
          status
          walkscore
          description
          logo_url
          more_info_icon
          more_info_link
          ws_link
          help_link
          snapped_lat
          snapped_lon
          bike {
            score
            description
            summary
          }
          transit {
            score
            description
            summary
          }
        }
      }
    `,
    {
      variables: {
        id,
      },
    },
  )

  return (
    <div className="">
      <Title as="h3" size="md">
        Walkscore<sup className="super">&#174;</sup>
      </Title>
      <p className="text-sm font-semibold mb-0 dark:text-white text-black">
        {!loading ? (
          <a href={data?.getWalkScoreById.ws_link} className="no-underline" rel="noopener noreferer">
            From Walkscore
          </a>
        ) : (
          <Skeleton />
        )}
      </p>
      <p className="text-sm mb-0 font-medium">
        {!loading ? (
          <a href={data?.getWalkScoreById.help_link} className="underline text-brand" rel="noopener noreferer">
            How it works.
          </a>
        ) : (
          <Skeleton />
        )}
      </p>
      <dl className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-3 ">
        {!loading ? (
          <div className="relative text-center">
            <dt>
              <p className="tracking-tight font-semibold dark:text-white text-black truncate">Walk</p>
              <p className="dark:text-white text-black text-xs font-medium mt-1">
                {data?.getWalkScoreById.description || <Skeleton />}
              </p>
            </dt>
            <dd className="flex items-baseline justify-center ">
              <p className="text-xl dark:text-white text-black font-bold">
                {data?.getWalkScoreById.walkscore || <Skeleton />}
              </p>
            </dd>
          </div>
        ) : (
          <Skeleton count={3} />
        )}
        {!loading && data?.getWalkScoreById.bike?.score ? (
          <div className="relative px-6 text-center">
            <dt className="pt-6 sm:pt-0">
              <p className="tracking-tight font-semibold dark:text-white text-black truncate">Bike</p>
              <p className="dark:text-white text-black text-xs font-medium mt-1">
                {data?.getWalkScoreById.bike.description || <Skeleton />}
              </p>
            </dt>
            <dd className="flex items-baseline justify-center ">
              <p className="text-xl dark:text-white text-black font-bold">
                {data?.getWalkScoreById.bike.score || <Skeleton />}
              </p>
            </dd>
          </div>
        ) : (
          <Skeleton count={3} />
        )}
        {!loading && data?.getWalkScoreById.transit?.score ? (
          <div className="relative px-6 text-center">
            <dt className="pt-6 sm:pt-0">
              <p className="tracking-tight font-semibold dark:text-white text-black truncate">Transit</p>
              <p className="dark:text-white text-black text-xs font-medium mt-1">
                {data?.getWalkScoreById.transit.description || <Skeleton />}
              </p>
            </dt>
            <dd className="flex items-baseline justify-center ">
              <p className="tracking-tight font-bold dark:text-white text-black truncate text-xl ">
                {data?.getWalkScoreById.transit.score || <Skeleton />}
              </p>
            </dd>
          </div>
        ) : (
          <Skeleton count={3} />
        )}
      </dl>
    </div>
  )
}
