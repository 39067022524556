/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect } from 'react'
import shallow from 'zustand/shallow'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, ExclamationIcon, XCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import useStore from '@/store'

export const Notification = () => {
  const {
    notificationTitle,
    notificationActive,
    notificationMessage,
    resetNotification,
    clearNotification,
    notificationType,
    clearable,
  } = useStore(
    ({
      notificationTitle,
      notificationActive,
      notificationMessage,
      clearNotification,
      notificationType,
      resetNotification,
      clearable,
    }) => ({
      notificationTitle,
      notificationActive,
      notificationMessage,
      clearNotification,
      resetNotification,
      notificationType,
      clearable,
    }),
    shallow,
  )

  let icon
  if (notificationType === 'warning') {
    icon = <ExclamationIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
  } else if (notificationType === 'error') {
    icon = <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
  } else if (notificationType === 'success') {
    icon = <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
  }

  useEffect(() => {
    if (clearable && notificationTitle) {
      const timer = setTimeout(() => {
        clearNotification()
      }, 3000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [clearable, notificationTitle, clearNotification])

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed top-20 right-0 w-full max-w-md flex items-end px-6 py-6 pointer-events-none sm:p-6 sm:items-start z-[99999]"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={notificationActive}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={resetNotification}
          >
            <div className="max-w-md w-full rounded-lg shadow-lg border border-zinc-200 dark:border-zinc-800 overflow-hidden dark:bg-zinc-700 bg-white ">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">{icon}</div>
                  <div className="ml-3 w-0 flex-1">
                    <p className="text-shadow font-semibold">{notificationTitle}</p>
                    <p className="mt-1 text-zinc-500 dark:text-zinc-300">{notificationMessage}</p>
                  </div>
                  {typeof clearable === 'boolean' && !clearable ? (
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        className="bg-white dark:bg-zinc-700 rounded-md inline-flex text-shadow hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                        onClick={clearNotification}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}
