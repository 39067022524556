import { Badge, Rating } from '@/components'
import useFavourite from '@/hooks/useFavourite'
import Link from 'next/link'
import { BsFillHeartFill, BsHeart } from 'react-icons/bs'
import { FaBed, FaShower } from 'react-icons/fa'

function average(list: any[], property: any): number | null {
  return list.reduce((a, b) => a + (b[property] ? Number(b[property]) : 0), 0) / list.length || null
}

export const ListingCard = ({
  loggedIn,
  listing,
  replaceURL = false,
}: {
  loggedIn?
  listing
  replaceURL?: boolean
}) => {
  const { favourites, addFavourite, removeFavourite, favLoaded } = useFavourite()
  const isFavourite = favourites.includes(listing.id)

  const averageRating = average(listing.reviews, 'rating')
  const bedrooms = average(listing.reviews, 'bedrooms')
  const bathrooms = average(listing.reviews, 'bathrooms')

  return (
    <div className="relative group">
      {replaceURL ? (
        <Link href={`/explore?listingid=${listing.id}`} passHref replace shallow prefetch={false}>
          <a className="absolute inset-0 select-none focus:outline focus:outline-brand focus:rounded-md  focus:outline-2 focus:outline-offset-4 focus:ring-transparent transition">
            <span className="sr-only">
              {(listing.unitNumber ? `${listing.unitNumber} - ` : '') + `${listing.streetNumber} ${listing.streetName}`}
            </span>
          </a>
        </Link>
      ) : (
        <Link href={`/listing/${listing.id}`} passHref>
          <a className="absolute inset-0 select-none focus:outline focus:outline-brand focus:rounded-md  focus:outline-2 focus:outline-offset-4 focus:ring-transparent transition">
            <span className="sr-only">
              {(listing.unitNumber ? `${listing.unitNumber} - ` : '') + `${listing.streetNumber} ${listing.streetName}`}
            </span>
          </a>
        </Link>
      )}
      <div className="flex flex-col items-start col-span-12">
        {listing.forRent ? (
          <div className="mb-2">
            <Badge>For Rent</Badge>
          </div>
        ) : null}

        <h3 className="tracking-tighter font-sans font-extrabold text-lg sm:text-xl text-zinc-700 dark:text-zinc-200 hover:text-brand group-hover:text-brand">
          {(listing.unitNumber ? `${listing.unitNumber} - ` : '') + `${listing.streetNumber} ${listing.streetName}`}
        </h3>

        <p className="dark:text-zinc-200 text-zinc-600 text-sm font-semibold mt-0">{`${listing.city} ${
          listing.region
        }, ${listing.country.charAt(0).toUpperCase() + listing.country.slice(1)}`}</p>
      </div>

      {listing.reviews.length ? (
        <div className="col-span-12 mt-2">
          <p className="dark:text-zinc-200 text-zinc-600 font-medium text-base mb-1 prose truncate">
            {listing.reviews[0].content}
          </p>
          <div className="flex items-center space-x-2">
            <Rating small value={averageRating} />

            <p className="text-sm font-semibold text-zinc-700 dark:text-zinc-200">
              Based on {listing.reviews.length} {listing.reviews.length > 1 ? 'reviews' : 'review'}
            </p>
          </div>
        </div>
      ) : null}

      <div className=" mt-3 text-zinc-700 dark:text-zinc-200 flex items-center space-x-2 align-middle">
        {listing?.listingType ? (
          <>
            <p className="text-sm font-semibold">
              <span className="capitalize inline-block">{listing?.listingType.toLowerCase()}</span>
            </p>
            <span aria-hidden> · </span>
          </>
        ) : null}
        <p className="text-sm font-semibold mb-0.5">
          <span className="relative top-0.5  inline-block mr-2">
            <FaBed className="w-3.5 h-3.5 " aria-hidden />
          </span>
          <span className="inline-block mr-1">{bedrooms}</span>
          Bedrooms
        </p>
        <span aria-hidden> · </span>
        <p className="text-sm  font-semibold">
          <span className="relative top-0.5  inline-block mr-2">
            <FaShower className="w-3.5 h-3.5 " aria-hidden />
          </span>
          <span className="inline-block mr-1">{bathrooms}</span>
          Bathrooms
        </p>
      </div>

      {loggedIn && favLoaded ? (
        <button
          type="button"
          className="absolute top-0 right-2 p-3 hover:bg-zinc-100 hover:dark:bg-black rounded-full align-middle"
          onClick={() => (isFavourite ? removeFavourite(listing.id) : addFavourite(listing.id))}
        >
          {isFavourite ? (
            <BsFillHeartFill className="h-5 w-5 text-red-500 dark:text-red-500" />
          ) : (
            <BsHeart className="h-5 w-5 text-black dark:text-white" />
          )}
        </button>
      ) : null}
    </div>
  )
}
