import clsx from 'clsx'

interface LabelProps {
  name: string
  description?: string
  error: string
  label: string
  className?: string
  required?: boolean
}

export const Label = ({
  name = '',
  description = '',
  error = '',
  label = '',
  className = '',
  required = false,
}: LabelProps) => (
  <div className={className}>
    <label
      htmlFor={name}
      className={clsx(
        'block font-serif leading-normal text-base tracking-tight font-bold',
        description ? 'mb' : '',
        error ? 'text-rose-500 ' : 'dark:text-white text-black',
      )}
    >
      {label}
      {required ? (
        <>
          <span className="inline-block ml-1 text-xl font-bold text-pink-500">*</span>
          <span className="sr-only">Required</span>
        </>
      ) : null}
    </label>
    {description && (
      <p
        className="font-sans text-sm dark:text-white/80 text-black/80 font-medium first-letter:capitalize"
        id={`${name}-description`}
      >
        {description}
      </p>
    )}
  </div>
)

Label.displayProps = 'Label'
