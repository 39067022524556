import { Map, Marker } from 'react-map-gl'

const MAPBOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN

interface PinProps {
  lat: number
  lng: number
  onClick?: any
  title?: string
}

export const Pin = ({ lat, lng }: PinProps) => (
  <Marker latitude={lat} longitude={lng}>
    <svg className="text-zinc-800 fill-current stroke-0 " viewBox="0 0 16 16" width="16" height="16">
      <circle cx="8" cy="8" r="8" className="text-zinc-800" />
    </svg>
  </Marker>
)

Pin.displayName = 'Pin'

export type MapProps = {
  config: {
    width?: string | number
    height?: string | number
    latitude: number
    longitude: number
    zoom?: number
  }
  showMarker?: boolean
}

export const StaticMap = ({
  showMarker = true,
  config: { width = '100%', height = 600, latitude = 0, longitude = 0, zoom = 13 },
}: MapProps) => {
  return (
    <Map
      style={{ width, height }}
      latitude={latitude}
      longitude={longitude}
      zoom={zoom}
      mapStyle="mapbox://styles/mapbox/outdoors-v11?optimize=true"
      mapboxAccessToken={MAPBOX_TOKEN}
      pitch={35}
      attributionControl={false}
      reuseMaps={true}
      interactive={false}
    >
      {showMarker && <Pin lat={latitude} lng={longitude} />}
    </Map>
  )
}

export default StaticMap
