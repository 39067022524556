export const Logo = ({ className }: { className: string }) => (
  <svg className={className} viewBox="0 0 975 389" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M272.234 212.676C263.933 221.242 246.474 227.039 222.516 222.691C204.553 219.539 159.93 206.937 115.082 205.95C117.483 193.993 119.203 181.636 120.266 169.316C121.9 155.431 131.67 122.261 139.769 121.807C139.769 121.807 140.848 117.586 137.661 115.576C136.98 115.176 135.642 114.814 134.122 115.118C139.89 100.563 133.861 83.1676 120.01 82.8502C105.722 82.5573 74.8521 91.9525 56.9062 139.812C46.0515 169.321 56.79 192.364 72.5637 195.638C83.6919 198.298 97.1251 191.195 106.939 170.502C105.933 176.032 102.354 190.463 96.6471 206.109C63.8153 207.952 32.5078 217.371 14.209 243.357C-9.02346 275.312 25.6431 313.87 57.4598 301.795C83.7085 292.221 101.653 263.847 111.444 223.232C114.995 223.908 143.503 231.066 157.794 235.299C181.887 242.048 209.313 249.485 225.377 250.116C271.769 251.235 276.728 226.217 276.503 214.407C276.38 212.224 273.899 211.05 272.234 212.676ZM82.6121 159.606C73.331 154.654 72.68 143.087 78.0716 129.648C86.3287 108.604 106.641 94.9852 113.416 98.3269C120.666 102.299 117.962 116.681 113.417 121.753C111.181 124.944 108.277 127.953 105.955 129.616C104.703 130.781 105.033 132.733 106.614 133.52C109.74 134.439 114.166 135.066 119.309 132.807C118.47 133.511 118.069 134.19 117.887 134.857C101.499 163.582 89.1684 162.96 82.6121 159.606ZM56.6234 263.527C47.0788 265.596 35.7864 252.217 41.6526 239.409C46.7047 227.741 54.9585 222.242 63.3842 219.799C74.4119 216.771 85.7712 219.637 90.8914 220.882C81.9408 241.308 70.0711 260.583 56.6234 263.527Z"
      fill="current"
    />
    <path
      d="M222.228 127.032C217.843 134.94 215.572 141.417 209.37 140.451C228.026 101.309 206.202 64.6581 177.569 94.2888C170.068 97.556 148.758 109.042 140.225 136.888C133.913 157.384 137.782 183.221 155.516 190.107C175.269 197.756 192.037 175.797 201.342 157.76C216.105 158.683 221.145 146.797 223.566 127.394C223.529 126.74 222.629 126.352 222.228 127.032ZM193.746 100.825C201.503 98.2002 202.655 114.775 193.594 133.236C183.861 124.149 182.33 104.75 193.746 100.825ZM184.958 151.455C180.512 158.272 171.638 164.463 165.069 160.89C149.889 152.547 160.134 120.007 171.075 115.452C170.777 125.759 176.273 145.374 184.958 151.455Z"
      fill="current"
    />
    <path
      d="M300.888 122.617C296.502 130.526 294.232 137.003 288.03 136.037C306.686 96.8952 284.862 60.2438 256.229 89.8746C248.728 93.1417 227.417 104.628 218.884 132.474C212.572 152.97 216.442 178.806 234.175 185.693C253.928 193.342 270.697 171.383 280.002 153.346C294.765 154.269 299.804 142.382 302.226 122.98C302.189 122.325 301.289 121.938 300.888 122.617ZM272.406 96.4106C280.162 93.786 281.315 110.361 272.254 128.822C262.52 119.735 260.989 100.335 272.406 96.4106ZM263.618 147.04C259.171 153.858 250.298 160.048 243.729 156.476C228.548 148.133 238.793 115.593 249.735 111.038C249.437 121.345 254.932 140.96 263.618 147.04Z"
      fill="current"
    />
    <path
      d="M426.959 21.3994C425.06 18.8787 419.926 13.4745 409.08 15.8346C391.729 19.6545 372.559 53.5708 367.411 67.4338C331.366 62.8886 287.555 25.7195 252.298 39.0828C235.095 45.5217 219.772 65.867 231.991 84.0098C232.965 85.7067 235.592 85.5593 236.369 83.7642C247.156 64.7681 261.502 58.2707 277.517 58.0287C304.719 57.5969 336.65 74.8525 363.441 78.8224C362.106 82.4004 361.208 85.9538 360.298 89.2889C358.847 83.0211 354.905 79.3015 349.396 78.9538C337.722 78.2953 319.119 83.2801 301.474 124.774C287.363 158.844 301.908 183.206 316.659 183.91C332.079 184.797 349.394 160.836 354.655 152.878C358.52 170.833 367.985 179.06 375.695 179.503C396.843 180.724 415.395 151.44 413.148 134.927C412.782 132.32 409.218 131.426 407.821 133.912C404.201 139.808 397.238 148.737 387.971 147.944C374.497 146.51 385.737 88.737 389.087 78.04C402.803 75.9567 415.665 70.4183 423.587 59.0269C436.783 39.8956 431.461 27.277 426.959 21.3994ZM392.644 67.1125C400.67 45.8631 410.124 34.3857 417.028 28.3059C422.784 36.9593 413.312 59.8224 392.644 67.1125ZM330.394 150.956C315.412 150.045 321.529 129.997 326.921 116.559C331.159 106.031 339.398 96.373 343.8 96.5639C352.617 97.1638 346.794 122.449 346.794 122.449C346.794 122.449 350.139 123.356 354.354 116.333C353.894 119.862 353.422 123.172 353.168 126.47C348.2 135.726 338.541 151.374 330.394 150.956Z"
      fill="current"
    />
    <path
      d="M371.645 256.686C366.832 269.732 352.502 270.767 354.755 256C356.552 246.74 360.714 232.115 361.846 226.928C366.225 205.156 352.489 185.806 340.438 205.343C334.822 214.32 328.862 226.696 329.531 224.087C330.954 218.021 330.405 209.939 327.894 204.235C325.177 198.561 320.293 195.294 312.782 198.478C310.992 199.156 310.674 201.295 311.736 202.815C312.562 204.16 313.507 206.325 313.985 209.603C314.617 215.371 302.664 249.962 298.3 266.082C289.25 298.995 311.133 299.375 311.133 299.375C313.143 281.505 321.83 256.177 325.798 245.975C330.82 234.363 338.361 219.875 340.801 220.777C346.828 223.25 336.314 257.632 334.289 269.645C331.714 285.086 345.257 297.349 356.056 293.689C370.816 288.406 377.964 269.789 373.379 257.062C373.435 256.008 371.906 255.602 371.645 256.686Z"
      fill="current"
    />
    <path
      d="M408.539 258.025C395.546 253.843 391.951 237.833 402.956 222.632C402.791 227.26 405.284 237.152 414.861 238.064C427.611 239.142 438.32 223.356 442.367 209.376C446.414 195.395 438.712 181.446 429.134 180.535C421.702 179.94 411.631 181.402 400.234 192.474C394.345 198.143 380.4 211.887 374.384 236.826C367.932 263.085 375.581 279.553 388.664 284.349C413.506 293.507 447.413 275.609 454.43 228.76C454.863 225.976 451.1 224.64 449.785 227.132C442.095 240.596 424.589 263.019 408.539 258.025ZM426.866 189.444C433.888 190.099 434.497 198.59 430.936 207.269C427.726 215.478 422.63 220.822 418.721 219.925C412.813 218.271 411.056 209.109 413.975 201.78C418.205 190.493 423.253 189.132 426.866 189.444Z"
      fill="current"
    />
    <path
      d="M472.473 169.637C477.734 171.175 483.687 167.381 485.521 161.255C487.356 155.129 484.374 149.075 479.083 147.332C473.821 145.794 467.869 149.588 466.034 155.714C464.199 161.84 467.211 168.099 472.473 169.637ZM468.256 277.395C488.73 278.189 498.228 248.349 501.187 234.107C503.683 222.444 498.078 224.305 498.078 224.305C498.078 224.305 491.233 246.435 479.485 246.467C472.964 246.367 473.005 239.456 473.932 234.299C474.511 231.075 481.049 203.967 481.049 203.967C481.049 203.967 484.689 191.511 477.974 184.324C470.758 176.583 462.6 179.651 462.6 179.651C462.6 179.651 465.519 183.831 463.333 190.427C461.82 195.878 458.535 209.33 450.943 237.846C447.278 251.562 445.462 276.52 468.256 277.395Z"
      fill="current"
    />
    <path
      d="M568.178 192.361C567.502 192.041 566.209 191.81 564.8 192.224C568.906 178.653 562.053 163.325 549.572 164.091C536.474 164.947 509.335 176.003 496.997 220.904C483.93 268.004 527.315 292.047 544.727 244.526C543.733 257.854 544.25 310.305 519.775 318.045C504.514 322.773 500.481 308.082 499.465 301.114C499.196 299.27 497.197 298.514 495.642 299.367C490.482 302.837 481.809 311.002 484.821 328.771C487.714 345.721 506.805 357.178 523.77 352.622C558.727 343.36 555.823 298.999 556.823 242.56C557.054 229.761 563.26 198.935 570.454 197.89C570.454 197.89 571.149 194.022 568.178 192.361ZM551.662 211.501C539.279 238.83 528.001 239.212 521.974 236.739C513.035 233.015 511.51 222.563 515.325 209.872C521.061 190.205 538.53 176.158 544.793 178.806C551.732 181.775 550.297 194.958 546.845 200.063C544.973 203.055 542.454 205.932 540.608 207.665C539.525 208.869 539.999 210.683 541.528 211.089C544.349 211.726 548.373 211.979 552.831 209.448C552.304 210.152 551.983 210.826 551.662 211.501Z"
      fill="current"
    />
    <path
      d="M648.636 124.593C654.278 114.357 650.421 99.431 643.191 97.342C635.93 95.0481 621.398 97.577 610.419 111.518C602.427 121.468 594.471 134.552 589.599 147.187C572.884 146.266 562.014 133.615 543.431 125.641C513.144 112.251 478.205 128.835 493.601 153.804C494.457 155.354 497.129 154.966 497.301 153.266C498.097 142.897 502.816 139.282 509.953 139.292C526.403 139.833 568.12 166.839 586.147 163.802C585.272 166.441 567.266 234.347 578.294 250.952C586.343 262.967 597.441 261.355 603.764 252.904C603.764 252.904 598.244 253.914 599.817 237.363C600.415 229.952 601.768 219.083 604.783 203.787C607.421 190.22 611.946 179.518 619.169 178.678C628.242 177.57 623.366 200.251 621.019 212.939C615.433 245.14 614.224 288.634 642.197 293.361C652.305 295.032 667.297 288.461 669.89 268.832C669.89 268.832 655.631 274.669 647.208 265.847C642.26 260.706 641.001 250.635 641.636 234.848C642.132 222.429 644 206.462 646.122 186.483C647.815 170.751 642.564 162.097 635.158 160.243C624.254 157.431 608.567 167.871 600.253 190.005C601.524 181.45 606.239 164.861 606.998 162.867C616.089 157.571 635.744 148.438 648.636 124.593ZM620.957 120.451C628.946 109.037 633.957 104.542 639.361 104.176C645.821 103.866 639.391 115.891 637.052 119.997C627.254 136.278 607.599 145.411 607.599 145.411C610.951 135.298 616.421 126.76 620.957 120.451Z"
      fill="current"
    />
    <path
      d="M746.579 167.082C739.815 175.388 731.695 181.59 727.966 181.922C727.831 175.245 726.404 168.337 724.984 164.358C720.903 153.65 713.037 144.329 699.947 148.114C690.848 150.481 682.317 156.743 678.517 175.291C678.977 171.249 680.826 159.47 685.351 148.768L687.025 148.734C700.175 145.36 712.054 139.031 718.544 127.416C729.597 108.232 723.487 96.5634 718.51 91.2174C716.507 88.9971 711.208 84.3256 700.966 87.4871C684.677 92.3637 673.064 122.092 659.575 164.858C653.193 184.41 650.358 246.974 686.893 245.645C707.774 244.914 720.68 226.927 726.801 196.95C733.347 195.79 740.174 189.357 742.898 186.45C749.665 179.608 746.579 167.082 746.579 167.082ZM709.512 98.5929C715.7 106.483 708.739 129.258 689.641 137.891C695.372 116.76 703.507 104.906 709.512 98.5929ZM716.079 173.813C716.184 180.285 713.348 185.301 713.348 185.301C694.888 191.12 695.415 178.906 696.573 172.46C697.731 166.014 701.711 160.204 705.818 158.143C712.301 155.109 716.236 166.256 716.079 173.813ZM692.512 215.113C683.525 215.371 677.756 210.35 679.594 182.669C681.313 200.207 693.372 206.617 709.073 202.035C704.84 211.858 698.973 214.802 692.512 215.113Z"
      fill="current"
    />
    <path
      d="M811.885 168.689C808.41 176.518 806.81 182.819 800.876 182.426C815.198 143.933 791.529 111.167 766.994 141.516C760.189 145.225 741.043 157.841 735.326 184.83C731.089 204.697 736.908 228.755 754.22 233.775C773.502 239.346 787.485 217.225 794.752 199.429C808.761 199.068 812.52 187.431 813.177 168.92C813.088 168.305 812.206 168.015 811.885 168.689ZM782.808 146.335C789.908 143.211 792.386 158.756 785.384 176.932C775.436 169.169 772.364 150.99 782.808 146.335ZM778.762 194.845C775.138 201.649 767.283 208.231 760.785 205.408C745.759 198.8 752.698 167.24 762.641 162.029C763.224 171.78 770.056 189.831 778.762 194.845Z"
      fill="current"
    />
    <path
      d="M893.148 166.724C893.148 166.724 884.957 191.142 876.669 188.998C870.556 187.374 871.09 178.089 875.398 163.024L881.317 146.05C881.317 146.05 885.808 133.679 878.446 126.377C871.085 119.076 861.605 121.708 861.605 121.708C865.005 130.631 850.459 161.832 845.586 174.468C840.247 188.218 834.918 194.851 829.04 193.403C824.691 192.36 826.282 183.131 829.6 171.348C831.988 163.259 834.402 153.91 836.319 145.47C840.5 126.656 829.289 118.867 816.7 123.207C822.765 140.323 810.569 171.81 806.018 195.281C802.51 212.951 810.996 223.647 820.282 225.437C829.363 227.258 840.976 220.549 849.922 204.182C855.504 193.536 857.985 176.016 863.575 156.788C864.632 156.844 864.352 162.116 862.462 169.297C857.78 187.555 855.617 214.446 870.018 218.215C884.655 222.158 893.836 194.457 895.801 182.034C898.054 167.267 893.148 166.724 893.148 166.724Z"
      fill="current"
    />
    <path
      d="M961.716 140.444C957.367 150.911 952.606 149.928 951.369 148.643C950.633 147.913 950.509 145.629 951.41 141.731C952.516 137.804 954.265 132.527 956.921 126.282C960.512 117.808 960.191 106.973 944.1 108.891C934.205 110.119 926.915 119.13 923.201 125.319C922.487 121.865 921.332 118.266 918.623 115.521C914.882 111.46 906.632 112.449 902.14 113.31C900.907 113.489 900.47 114.809 901.266 115.949C902.091 117.294 903.242 119.428 903.545 122.942C904.382 128.68 892.254 163.506 887.889 179.625C883.873 193.811 882.135 203.48 887.789 209.147C896.388 217.734 905.483 213.901 905.483 213.901C903.259 205.854 906.716 190.703 911.349 176.428C916.158 161.918 920.324 148.757 920.324 148.757L920.294 148.552C923.582 136.564 933.155 124.502 937.296 124.11C939.381 124.016 939.945 126.445 938.14 132.776C936.19 139.547 933.447 146.642 932.229 152.678C925.982 178.907 964.957 178.478 961.716 140.444Z"
      fill="current"
    />
    <path
      d="M668.299 367.695C668.299 367.695 661.267 324.76 802.06 287.844C843.318 276.962 903.238 270.708 936.908 270.259C959.575 270.032 959.173 280.024 959.173 280.024C970.994 269.433 964.747 253.969 948.566 251.179C914.794 245.653 771.976 243.616 667.406 304.612C652.75 313.103 641.1 330.132 642.822 344.852C643.986 354.768 651.449 363.587 668.299 367.695Z"
      fill="current"
    />
  </svg>
)
