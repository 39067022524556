import Link from 'next/link'
import clsx from 'clsx'

export type ButtonLinkProps = {
  children: React.ReactNode
  onClick?: (...args: any[]) => any
  url?: string
  openInNewWindow?: boolean
  type?: 'button' | 'submit' | 'reset'
  small?: boolean
  internal?: boolean
  className?: string
  purple?: boolean
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  url,
  internal = false,
  children,
  type = 'button',
  onClick = () => null,
  openInNewWindow = false,
  small = false,
  purple = false,
  className = '',
}: ButtonLinkProps) => {
  const classNames = clsx(
    'font-sans rounded-sm outline-transparent inline-flex items-center focus:outline focus:outline-offset-4 focus:outline-current font-semibold underline underline-offset-8 decoration-brand  tracking-tight hover:decoration-brand-300',
    purple ? 'text-brand-700 transition hover:text-zinc-800 ' : 'transition ',
    small ? 'text-sm' : 'text-base',
    className,
  )

  if (url) {
    if (internal) {
      return (
        <Link href={url} passHref>
          <a className={classNames}>{children}</a>
        </Link>
      )
    } else {
      return (
        <a href={url} className={classNames} target={openInNewWindow ? '_blank' : ''} rel="noreferrer">
          {children}
        </a>
      )
    }
  }
  return (
    <button className={classNames} type={type} onClick={onClick}>
      {children}
    </button>
  )
}
