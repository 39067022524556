import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import Script from 'next/script'

const isProduction = process.env.NODE_ENV === 'production'

interface Props {
  children?: JSX.Element[] | JSX.Element
  title: string
  description?: string
}

export const Head: React.FC<Props> = ({ children, title, description }: Props) => {
  const { asPath } = useRouter()
  return (
    <>
      <NextHead>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="theme-color" content="#572DFF"></meta>
        <link rel="shortcut icon" href="/favicon.png" />

        {children}
      </NextHead>
      <Script
        id="crisp-widget"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.$crisp=[];
          window.CRISP_WEBSITE_ID="bbd0945a-bd8c-4c22-bbe0-e8fb8133174b";
          (function(){
            const d = document;
            const s = d.createElement("script");
            s.src = "https://client.crisp.chat/l.js";
            s.async = 1;
            d.getElementsByTagName("head")[0].appendChild(s);
          })();`,
        }}
      />

      <NextSeo
        title={title.length ? `Good Neighbour | ${title}` : 'Good Neighbour'}
        description={
          description || 'Review your apartment and landlord with Good Neighbour. List your apartment and help others'
        }
        canonical={`https://goodnbr.co${asPath}`}
        openGraph={{
          url: `https://goodnbr.co${asPath}`,
          title: title.length ? `Good Neighbour | ${title}` : 'Good Neighbour',
          description:
            description ||
            'Review your apartment and landlord with Good Neighbour. List your apartment and help others',
          images: [
            {
              url: 'https://goodnbr.co/og/gn-og1.png',
              width: 1012,
              height: 506,
              alt: 'Good Neighbour Promo',
              type: 'image/png',
            },
            {
              url: 'https://goodnbr.co/og/gn-og2.png',
              width: 1012,
              height: 506,
              alt: 'Good Neighbour Promo',
              type: 'image/png',
            },
            {
              url: 'https://goodnbr.co/og/gn-og3.png',
              width: 1012,
              height: 506,
              alt: 'Good Neighbour Promo',
              type: 'image/png',
            },
            {
              url: 'https://goodnbr.co/og/gn-og4.png',
              width: 1012,
              height: 506,
              alt: 'Good Neighbour Promo',
              type: 'image/png',
            },
          ],
          site_name: 'GoodNeighbour',
        }}
        twitter={{
          handle: '@goodnbrco',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
    </>
  )
}
