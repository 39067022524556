import { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { ChatIcon, XIcon } from '@heroicons/react/outline'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'

import { Button, Title } from '@/components'
import { NEW_CONVERSATION } from '@/queries'

export const ForRentMessage = ({ recipient, open, setOpen }) => {
  const [createMessage, { loading }] = useMutation(NEW_CONVERSATION)
  const { handleSubmit, register, reset } = useForm()

  const postComment = async (data) => {
    try {
      await createMessage({
        variables: { content: data.comment, recipient },
      })
      reset()
      setOpen(false)
      toast.success('Message sent successfully')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 dark:bg-zinc-800 bg-zinc-600 dark:bg-opacity-50 backdrop-blur-sm  bg-opacity-50 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom dark:bg-zinc-900 bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <header className="flex items-center space-x-3 mb-2">
                <div className="flex items-center justify-center h-10 w-10 rounded-full bg-green-100">
                  <ChatIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <Title as="h3">New message</Title>
              </header>

              <form onSubmit={handleSubmit((data) => postComment(data))} className="my-4">
                <div className="relative w-full mb-4">
                  <label htmlFor="comment" className="sr-only">
                    New message
                  </label>
                  <textarea
                    {...register('comment')}
                    rows={3}
                    name="comment"
                    id="comment"
                    required
                    className={clsx(
                      'border transition   dark:bg-zinc-800  bg-sand-200  font-sans appearance-none block w-full  rounded-md  focus:outline-none placeholder-zinc-400 focus:ring-1  peer relative z-[1] shadow-input font-medium selection:bg-brand-100 selection:dark:bg-brand-400 p-3 text-base caret-brand-500 text-zinc-800 dark:text-white focus:dark:border-transparent focus:border-transparent focus:ring-transparent border-zinc-300 dark:border-zinc-600 disabled:opacity-50',
                    )}
                    placeholder="Write a message..."
                    defaultValue={''}
                  />
                  <span className='hidden peer-focus:block after:content-[""] after:absolute after:-inset-[3px] after:bg-gradient-to-r after:from-holly-300 after:dark:from-holly-300  after:to-brand-500 after:dark:to-brand-500 after:rounded-lg -z-[1] after:pointer-events-none' />
                </div>
                <div className="flex justify-end items-center">
                  {loading ? (
                    <div className="mr-4 text-center">
                      <svg
                        role="status"
                        className="inline w-5 h-5 text-zinc-200 animate-spin dark:text-zinc-700 fill-brand-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                  ) : null}
                  <Button small type="submit">
                    Send
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
