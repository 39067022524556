import clsx from 'clsx'
interface Props {
  children: React.ReactNode
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p'
  size?: '2xl' | 'xl' | 'lg' | 'md' | 'sm'
  className?: string
  gradient?: boolean
}

export const Title: React.FC<Props> = ({
  as = 'h1',
  children,
  className = '',
  size = 'md',
  gradient = false,
}: Props) => {
  const CustomTag = `${as}` as keyof JSX.IntrinsicElements

  const cn = clsx(
    'font-sans  font-extrabold text-zinc-700 dark:text-zinc-200 tracking-tighter',
    className,
    size === '2xl' && 'text-4xl sm:text-6xl',
    size === 'xl' && 'text-3xl sm:text-5xl',
    size === 'lg' && 'text-2xl sm:text-4xl',
    size === 'md' && 'text-xl sm:text-2xl',
    size === 'sm' && 'text-sm sm:text-base',
  )

  return (
    <CustomTag className={cn}>
      {gradient ? (
        <span className="text-transparent bg-clip-text bg-gradient-to-br from-brand-600 to-brand-500 dark:from-brand-400 dark:via-brand-500 dark:to-brand-600 ">
          {children}
        </span>
      ) : (
        children
      )}
    </CustomTag>
  )
}
