import clsx from 'clsx'

export const Card = ({
  children,
  className = '',
  feature = false,
  borderColor = '',
  hideOnMobile = false,
}: {
  children: React.ReactNode
  className?: string
  feature?: boolean
  borderColor?: string
  hideOnMobile?: boolean
}) => (
  <div className={clsx('relative', className)}>
    <div
      className={clsx(
        ' hidden w-full h-3/4 absolute bg-radial  from-brand-500/10 via-transparent to-transparent -bottom-1/4 left-0 blur-lg',
        hideOnMobile ? 'hidden lg:block' : 'block',
      )}
    />
    <div
      className={clsx(
        'dark:backdrop-blur-xl shadow-subtler dark:shadow-subtle dark:bg-zinc-900/90 bg-white relative',
        hideOnMobile ? 'rounded-none lg:rounded-lg border-l-0 ' : '',
        feature ? 'rounded-none rounded-r-lg lg:border-l-4' : 'rounded-lg',
        feature && borderColor ? 'border-l-2 ' : '',
        borderColor,
      )}
    >
      <div className="dark:shadow-inner">
        <div
          className={clsx(
            'px-6 py-4 border-2 border-black/10 dark:border-zinc-500/20',
            hideOnMobile ? 'rounded-none lg:rounded-lg' : '',
            feature ? 'lg:rounded-none lg:rounded-r-lg border-l-0' : 'rounded-lg',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  </div>
)
