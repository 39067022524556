import clsx from 'clsx'

interface BadgeProps {
  children: React.ReactNode
  large?: boolean
}

export const Badge = ({ children, large = false }: BadgeProps) => (
  <span
    className={clsx(
      'inline-flex items-center py-1 px-3 rounded-full font-bold bg-emerald-100 text-emerald-800 border border-emerald-200 shadow-xl ',
      large ? 'px-3 py-0.5 text-md' : 'px-2.5 py-0.5 text-sm',
    )}
  >
    {children}
  </span>
)
