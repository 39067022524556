import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { Loading } from '@/components'
import { useEffect } from 'react'

export const ProtectedPage = ({ children }: { children: React.ReactNode }) => {
  const { status } = useSession()
  const { push, asPath } = useRouter()

  useEffect(() => {
    if (status === 'unauthenticated') {
      push(`/auth/login?redirect=${asPath}`)
    }
  }, [status])

  if (status === 'loading') {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Loading />
      </div>
    )
  } else if (status === 'authenticated') {
    return <>{children}</>
  } else {
    return null
  }
}
