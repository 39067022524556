import { SetState } from 'zustand'
import { MyState } from '@/store'

export interface DarkModeState {
  mode: boolean
  setDarkMode: (val: boolean) => void
}

const createDarkModeState = (set: SetState<MyState>) => ({
  mode: false,
  setDarkMode: (val: boolean) => {
    set(() => ({ mode: val }))
  },
})

export default createDarkModeState
