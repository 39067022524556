import create from 'zustand'

import createLocationState, { LocationState } from './locationState'
import createNotificationState, { NotificationState } from './notificationState'
import createSearchState, { SearchState } from './searchStore'
import createDarkModeState, { DarkModeState } from './darkmodeState'
import createLoadingState, { LoadingState } from './loadingState'

export type MyState = LocationState & NotificationState & SearchState & DarkModeState & LoadingState

const useStore = create<MyState>((set) => ({
  ...createLocationState(set),
  ...createNotificationState(set),
  ...createSearchState(set),
  ...createDarkModeState(set),
  ...createLoadingState(set),
}))

export default useStore
