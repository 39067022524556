import { SetState } from 'zustand'
import { MyState } from '@/store'

export interface LoadingState {
  loading: boolean
  startLoading: () => void
  endLoading: () => void
}

const createLoadingState = (set: SetState<MyState>) => ({
  loading: false,
  startLoading: () => {
    set(() => ({ loading: true }))
  },
  endLoading: () => {
    set(() => ({ loading: false }))
  },
})

export default createLoadingState
