import Link from 'next/link'
import clsx from 'clsx'

export type ButtonProps = {
  children: React.ReactNode
  onClick?: (...args: any[]) => any
  url?: string
  openInNewWindow?: boolean
  type?: 'button' | 'submit' | 'reset'
  small?: boolean
  internal?: boolean
  outline?: boolean
  forward?: boolean
  back?: boolean
  className?: string
  alt?: boolean
  arrow?: boolean
  color?: string
  withIcon?: JSX.Element
  disabled?: boolean
  rounded?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  url,
  internal = false,
  children,
  rounded = false,
  type = 'button',
  onClick = () => null,
  openInNewWindow = false,
  small = false,
  outline = false,
  alt = false,
  className = '',
  color,
  forward = false,
  back = false,
  disabled = false,
  withIcon,
}: ButtonProps) => {
  // const classNames = clsx(
  //   color ? color : 'bg-black dark:bg-white  focus:ring-brand-500 ',
  //   'inline-flex items-center  dark:border-brand-500 border-brand-500  text-brand-900  focus:outline-none focus:ring-2 focus:ring-offset-2 justify-center  border leading-normal align-bottom select-none transition-colors ease-out duration-250 whitespace-nowrap font-regular capitalize tracking-tight font-serif',
  //   rounded ? 'rounded-full !p-2' : 'rounded-full',
  //   alt && !outline && 'dark:!bg-white !bg-text hover:bg-text/70 !text-white dark:!text-black !border-transparent  ',
  //   outline && !alt && 'hover:!bg-brand-400/10  border-2 !bg-transparent',
  //   !outline && !alt && 'border-transparent ',
  //   small && !rounded ? 'text-sm px-5 pt-1.5 pb-2' : 'text-base px-10 pt-2 pb-2.5',
  //   disabled ? '' : 'hover:bg-brand-500/80 hover:shadow-subtler',
  //   className,
  // )

  const classNames = clsx(
    'bg-zinc-800 text-snow dark:text-zinc-800 dark:bg-snow font-sans border-2 rounded-full border-transparent whitespace-nowrap font-semibold capitalize  leading-normal align-bottom select-none inline-flex items-center justify-center focus:outline focus:outline-brand  focus:outline-2 focus:outline-offset-4 focus:ring-transparent shadow-lg  hover:shadow-xl shadow-zinc-900/20 transition-all',
    alt
      ? '!bg-brand dark:!bg-brand !text-violet-50 dark:!text-violet-50 !border-brand dark:!border-brand shadow-lg !shadow-brand-700/40 dark:shadow-brand-700/50 dark:hover:shadow-brand-700/50 hover:shadow-brand-700/30 hover:!bg-brand/80 dark:hover:!bg-brand/80 hover:shadow-md stroke-white'
      : '',
    rounded && '!p-2',
    small ? 'text-sm px-5 pt-1.5 pb-2' : 'text-base px-10 pt-2 pb-2.5',
    className,
  )

  if (url) {
    if (internal) {
      return (
        <Link href={url} passHref>
          <a className={clsx('relative peer', classNames)}>
            {withIcon}
            {back ? <Chevron className="relative top-px mr-2 rotate-180" /> : null}
            <span>{children}</span>
            {forward ? <Chevron className="relative top-px ml-2" /> : null}
          </a>
        </Link>
      )
    } else {
      return (
        <a
          href={url}
          className={clsx('relative peer', classNames)}
          target={openInNewWindow ? '_blank' : ''}
          rel="noreferrer"
        >
          {withIcon}
          {back ? <Chevron className="relative top-px mr-2 rotate-180" /> : null}
          <span>{children}</span>
          {forward ? <Chevron className="relative top-px ml-2" /> : null}
        </a>
      )
    }
  }
  return (
    <button className={clsx('relative peer', classNames)} type={type} onClick={onClick} disabled={disabled}>
      {withIcon}
      {back ? <Chevron className="relative top-px mr-2 rotate-180" /> : null}
      <span>{children}</span>
      {forward ? <Chevron className="relative top-px ml-2" /> : null}
    </button>
  )
}

const Chevron = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    className={className}
    width="15"
    height="15"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
  </svg>
)

const Arrow = ({ className }: { className?: string }) => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M17 8L1 8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 15L17 8L10 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const Cross = ({ className }: { className?: string }) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M9.2608 7.49893L14.6306 2.14085C14.8658 1.90566 14.9979 1.58668 14.9979 1.25408C14.9979 0.921478 14.8658 0.602498 14.6306 0.367312C14.3955 0.132126 14.0765 0 13.744 0C13.4114 0 13.0925 0.132126 12.8573 0.367312L7.5 5.73789L2.14268 0.367312C1.90752 0.132126 1.58859 -2.47808e-09 1.25603 0C0.923478 2.47809e-09 0.604543 0.132126 0.369391 0.367312C0.134239 0.602498 0.00213119 0.921478 0.00213119 1.25408C0.00213119 1.58668 0.134239 1.90566 0.369391 2.14085L5.7392 7.49893L0.369391 12.857C0.252343 12.9731 0.159441 13.1113 0.0960411 13.2635C0.0326416 13.4157 0 13.5789 0 13.7438C0 13.9087 0.0326416 14.0719 0.0960411 14.2241C0.159441 14.3763 0.252343 14.5144 0.369391 14.6306C0.485482 14.7476 0.6236 14.8405 0.775777 14.9039C0.927954 14.9674 1.09118 15 1.25603 15C1.42089 15 1.58411 14.9674 1.73629 14.9039C1.88847 14.8405 2.02659 14.7476 2.14268 14.6306L7.5 9.25998L12.8573 14.6306C12.9734 14.7476 13.1115 14.8405 13.2637 14.9039C13.4159 14.9674 13.5791 15 13.744 15C13.9088 15 14.072 14.9674 14.2242 14.9039C14.3764 14.8405 14.5145 14.7476 14.6306 14.6306C14.7477 14.5144 14.8406 14.3763 14.904 14.2241C14.9674 14.0719 15 13.9087 15 13.7438C15 13.5789 14.9674 13.4157 14.904 13.2635C14.8406 13.1113 14.7477 12.9731 14.6306 12.857L9.2608 7.49893Z"
      fill="current"
    />
  </svg>
)
