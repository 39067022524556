import { gql } from '@apollo/client'

export const REVIEW_FOR_ID = gql`
  query ReviewForListingId($reviewForListingId: String!) {
    reviewForListing(id: $reviewForListingId) {
      id
      content
      rating
      bedrooms
      bathrooms
      rentPrice
      rentFrequency
      active
      shareTenancy
      stillTenant
      tenancyStart
      tenancyEnd
      updatedAt
      createdAt
      listingId
      userId
      comments {
        id
        content
        updatedAt
        createdAt
        userId
        reviewId
      }
      images {
        id
        filePath
        fileType
        height
        width
        fileName
        fileId
        updatedAt
        createdAt
        reviewId
        rentalId
      }
      details {
        id
        petsWelcome
        petsWelcomeStory
        noisyNeighbours
        noisyNeighboursStory
        landlordResponsive
        landlordResponsiveStory
        wellMaintained
        wellMaintainedStory
        pests
        pestsStory
        landlordLanguages
        landlordLanguagesStory
        accessibility
        accessibilityStory
        dishwasherAvailable
        airConditioningIncluded
        covidFriendly
        parkingLocation
        parkingAvgPrice
        laundryLocation
        laundryIncluded
        laundryAvgPrice
        dryerIncluded
        dryerAvgPrice
        gasIncluded
        gasAvgPrice
        hydroIncluded
        hydroAvgPrice
        waterIncluded
        waterAvgPrice
        updatedAt
        createdAt
        reviewId
        rentalId
      }
    }
  }
`

export const NEARBY_LISTINGS = gql`
  query NearbyListingsById($nearbyListingsByIdId: String!) {
    nearbyListingsById(id: $nearbyListingsByIdId) {
      id
      listingType
      unitNumber
      streetNumber
      streetName
      city
      coordinates
      country
      region
      neighbourhood
      postalzip
      forRent
      latitude
      longitude
      shortcode
      normalized_address
      updatedAt
      createdAt
      parentListingId
      rentals {
        id
        active
      }
      reviews {
        id
        rating
        active
        content
        rentPrice
        bathrooms
        bedrooms
        details {
          waterAvgPrice
          waterIncluded
          hydroAvgPrice
          hydroIncluded
          gasAvgPrice
          gasIncluded
        }
      }
    }
  }
`

export const ALL_LISTINGS_QUERY = gql`
  query QueryAllListings(
    $first: Int
    $after: String
    $filters: FilterInputType
    $longitude: Float
    $latitude: Float
    $searchrange: SearchRangeInputType
  ) {
    queryListings(
      first: $first
      after: $after
      filters: $filters
      longitude: $longitude
      latitude: $latitude
      searchrange: $searchrange
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          latitude
          longitude
          forRent
          coordinates
          normalized_address
          unitNumber
          streetNumber
          streetName
          listingType
          city
          country
          region
          rentals {
            id
            active
          }
          reviews {
            id
            rating
            active
            content
            rentPrice
            bathrooms
            bedrooms
            details {
              waterAvgPrice
              waterIncluded
              hydroAvgPrice
              hydroIncluded
              gasAvgPrice
              gasIncluded
            }
          }
        }
      }
    }
  }
`

export const RENTAL_FOR_ID = gql`
  query ListingById($rentalsForListingId: String!) {
    rentalsForListing(id: $rentalsForListingId) {
      id
      description
      canPhone
      phoneNumber
      canEmail
      email
      bedrooms
      bathrooms
      rentPrice
      months
      startDate
      endDate
      availability
      storageAvailable
      controlledAccess
      outdoorSpace
      balcony
      active
      paid
      shortCode
      updatedAt
      createdAt

      userId

      details {
        id
        petsWelcome
        petsWelcomeStory
        noisyNeighbours
        noisyNeighboursStory
        landlordResponsive
        landlordResponsiveStory
        wellMaintained
        wellMaintainedStory
        pests
        pestsStory
        landlordLanguages
        landlordLanguagesStory
        accessibility
        accessibilityStory
        dishwasherAvailable
        airConditioningIncluded
        covidFriendly
        parkingLocation
        parkingAvgPrice
        laundryLocation
        laundryIncluded
        laundryAvgPrice
        dryerIncluded
        dryerAvgPrice
        gasIncluded
        gasAvgPrice
        hydroIncluded
        hydroAvgPrice
        waterIncluded
        waterAvgPrice
        updatedAt
        createdAt
      }
      images {
        id
        filePath
        fileType
        height
        width
        fileName
        fileId
        updatedAt
        createdAt
      }
      showings {
        id
        date
        updatedAt
        createdAt
        userId
      }
    }
  }
`

export const DELETE_REVIEW_BY_ID = gql`
  mutation DeleteReviewById($reviewId: String!) {
    deleteReviewById(reviewId: $reviewId) {
      id
    }
  }
`

export const LISTING_BY_ID = gql`
  query ListingById($id: String!) {
    listingById(id: $id) {
      id
      listingType
      unitNumber
      streetNumber
      streetName
      city
      country
      region
      neighbourhood
      postalzip
      forRent
      normalized_address
      longitude
      latitude
      updatedAt
      createdAt
      forRent

      listingType
      forRent
      reviews {
        id
        rating
        active
        content
        rentPrice
        bathrooms
        bedrooms
        details {
          waterAvgPrice
          waterIncluded
          hydroAvgPrice
          hydroIncluded
          gasAvgPrice
          gasIncluded
        }

        images {
          id
          filePath
          fileType
          height
          width
          fileName
          fileId
          updatedAt
          createdAt
        }
      }
    }
  }
`

export const ACTIVE_USER = gql`
  query ActiveUser {
    activeUser {
      id
      name
      image
      comments {
        id
        content
        updatedAt
        createdAt
        userId
      }
      favourites {
        id
      }
      rentals {
        id
        description
        canPhone
        phoneNumber
        canEmail
        email
        bedrooms
        bathrooms
        rentPrice
        months
        startDate
        endDate
        availability
        storageAvailable
        controlledAccess
        outdoorSpace
        balcony
        active
        paid
        shortCode
        updatedAt
        createdAt
        listingId
      }
      reviews {
        id
        content
        rating
        bedrooms
        bathrooms
        rentPrice
        rentFrequency
        active
        shareTenancy
        stillTenant
        tenancyStart
        tenancyEnd
        updatedAt
        createdAt
        listingId
        images {
          id
          filePath
          fileType
          height
          width
          fileName
          fileId
          updatedAt
          createdAt
        }
        details {
          id
          petsWelcome
          petsWelcomeStory
          noisyNeighbours
          noisyNeighboursStory
          landlordResponsive
          landlordResponsiveStory
          wellMaintained
          wellMaintainedStory
          pests
          pestsStory
          landlordLanguages
          landlordLanguagesStory
          accessibility
          accessibilityStory
          dishwasherAvailable
          airConditioningIncluded
          covidFriendly
          parkingLocation
          parkingAvgPrice
          laundryLocation
          laundryIncluded
          laundryAvgPrice
          dryerIncluded
          dryerAvgPrice
          gasIncluded
          gasAvgPrice
          hydroIncluded
          hydroAvgPrice
          waterIncluded
          waterAvgPrice
          updatedAt
          createdAt
        }
      }
    }
  }
`

export const GET_COMMENTS_FOR_REVIEW = gql`
  query GetCommentsForReview($id: String!) {
    getCommentsForReview(id: $id) {
      id
      content
      updatedAt
      createdAt
      userId
    }
  }
`

export const DELETE_COMMENT = gql`
  mutation DeleteCommentById($commentId: String!) {
    deleteCommentById(commentId: $commentId) {
      id
    }
  }
`

export const NEW_COMMENT = gql`
  mutation AddComment($reviewId: String!, $content: String!) {
    addComment(reviewId: $reviewId, content: $content) {
      id
    }
  }
`

export const GET_CONVERSATIONS = gql`
  query AllConversations($first: Int) {
    allConversations(first: $first) {
      edges {
        node {
          id
          messages {
            id
            content
            updatedAt
            createdAt
            userId
            user {
              id
              name
              email
            }
          }
          users {
            id
            name
            email
          }
        }
      }
    }
  }
`

export const GET_CONVERSATION_BY_ID = gql`
  query GetConversationById($getConversationById: String!) {
    getConversationById(id: $getConversationById) {
      id
      messages {
        id
        content
        updatedAt
        createdAt
        userId
        user {
          id
          name
          email
        }
      }
      users {
        id
        name
        email
      }
    }
  }
`

export const ADD_MESSAGE_TO_CONVERSATION = gql`
  mutation AddMessageToConversation($conversationId: String!, $content: String!) {
    addMessageToConversation(conversationId: $conversationId, content: $content) {
      id
      messages {
        id
        content
        updatedAt
        createdAt
        userId
        user {
          id
          name
          email
        }
      }
      users {
        id
        name
        email
      }
    }
  }
`

export const NEW_CONVERSATION = gql`
  mutation NewConversation($content: String!, $recipient: String!) {
    newConversation(content: $content, recipient: $recipient) {
      id
      messages {
        id
        content
        updatedAt
        createdAt
        userId
        user {
          id
          name
          email
        }
      }
      users {
        id
        name
        email
      }
    }
  }
`

export const GET_SHOWINGS_FOR_RENTAL = gql`
  query ShowingsForRental($showingsForRentalId: String!) {
    showingsForRental(id: $showingsForRentalId) {
      id
      date
      updatedAt
      createdAt
      userId
    }
  }
`

export const BOOK_SESSION_SHOWING = gql`
  mutation BookShowingSession($rentalId: String!, $showingTimeId: String!) {
    bookShowingSession(rentalId: $rentalId, showingTimeId: $showingTimeId) {
      id
    }
  }
`

export const UNBOOK_SESSION_SHOWING = gql`
  mutation UnbookSession($rentalId: String!, $showingTimeId: String!) {
    unbookSession(rentalId: $rentalId, showingTimeId: $showingTimeId) {
      id
    }
  }
`

export const SEARCH_FOR_ACTIVE_RENTAL = gql`
  query SearchForActiveRental(
    $streetNumber: String!
    $streetName: String!
    $city: String!
    $country: String!
    $postalZip: String!
    $unitNumber: String!
    $region: String!
  ) {
    searchForActiveRental(
      streetNumber: $streetNumber
      streetName: $streetName
      city: $city
      country: $country
      postalZip: $postalZip
      unitNumber: $unitNumber
      region: $region
    ) {
      id
    }
  }
`

export const CREATE_NEW_RENTAL_AND_LISTING = gql`
  mutation CreateNewRentalandListing(
    $listing: ListingInputType!
    $review: ReviewInputType!
    $details: DetailsInputType!
    $images: [ImageInputType]
  ) {
    createNewRentalandListing(listing: $listing, review: $review, details: $details, images: $images) {
      id
      rentals {
        id
      }
    }
  }
`

export const CREATE_NEW_REVIEW_AND_LISTING = gql`
  mutation CreateNewReviewandListing(
    $listing: ListingInputType!
    $review: ReviewInputType!
    $details: DetailsInputType!
    $images: [ImageInputType]
  ) {
    createNewReviewandListing(listing: $listing, review: $review, details: $details, images: $images) {
      id
    }
  }
`

export const CREATE_REVIEW_FOR_LISTING = gql`
  mutation CreateNewReview(
    $listingid: String!
    $review: ReviewInputType!
    $details: DetailsInputType!
    $images: [ImageInputType]
  ) {
    createNewReview(listingid: $listingid, review: $review, details: $details, images: $images) {
      id
    }
  }
`

export const EDIT_RENTAL_BY_ID = gql`
  mutation EditRentalById(
    $rentalId: String!
    $rental: RentalInputType!
    $details: DetailsInputType!
    $images: [ImageInputType]
  ) {
    editRentalById(rentalId: $rentalId, rental: $rental, details: $details, images: $images) {
      id
      listing {
        id
      }
    }
  }
`

export const EDIT_REVIEW_BY_ID = gql`
  mutation EditReviewById(
    $reviewId: String!
    $review: ReviewInputType!
    $details: DetailsInputType!
    $images: [ImageInputType]
  ) {
    editReviewById(reviewId: $reviewId, review: $review, details: $details, images: $images) {
      id
      listing {
        id
      }
    }
  }
`
export const REVIEW_BY_ID = gql`
  query ReviewById($reviewById: String!) {
    reviewById(id: $reviewById) {
      id
      content
      rating
      bedrooms
      bathrooms
      rentPrice
      rentFrequency
      active
      shareTenancy
      stillTenant
      tenancyStart
      tenancyEnd
      updatedAt
      createdAt
      listingId
      listing {
        id
        listingType
        unitNumber
        streetNumber
        streetName
        city
        coordinates
        country
        region
        neighbourhood
        postalzip
        forRent
        latitude
        longitude
        shortcode
        normalized_address
        updatedAt
        createdAt
        parentListingId
      }
      userId
      images {
        id
        filePath
        fileType
        height
        width
        fileName
        fileId
        updatedAt
        createdAt
      }
      details {
        id
        petsWelcome
        petsWelcomeStory
        noisyNeighbours
        noisyNeighboursStory
        landlordResponsive
        landlordResponsiveStory
        wellMaintained
        wellMaintainedStory
        pests
        pestsStory
        landlordLanguages
        landlordLanguagesStory
        accessibility
        accessibilityStory
        dishwasherAvailable
        airConditioningIncluded
        covidFriendly
        parkingLocation
        parkingAvgPrice
        laundryLocation
        laundryIncluded
        laundryAvgPrice
        dryerIncluded
        dryerAvgPrice
        gasIncluded
        gasAvgPrice
        hydroIncluded
        hydroAvgPrice
        waterIncluded
        waterAvgPrice
        updatedAt
        createdAt
      }
    }
  }
`

export const ACTIVATE_RENTAL = gql`
  mutation ActivateRental($rentalId: String!) {
    activateRental(rentalId: $rentalId) {
      id
      active
    }
  }
`

export const DEACTIVATE_RENTAL = gql`
  mutation DeactivateRental($rentalId: String!) {
    deactivateRental(rentalId: $rentalId) {
      id
      active
    }
  }
`

export const RENTAL_BY_ID = gql`
  query RentalById($rentalId: String!) {
    rentalById(id: $rentalId) {
      id
      description
      canPhone
      phoneNumber
      canEmail
      email
      bedrooms
      bathrooms
      rentPrice
      months
      startDate
      endDate
      availability
      storageAvailable
      controlledAccess
      outdoorSpace
      balcony
      active
      paid
      shortCode
      updatedAt
      createdAt
    }
  }
`
