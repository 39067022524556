import clsx from 'clsx'
import { useEffect, useState, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { geocodingClient } from '@/lib/MapboxGeocode'
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback'
import { useOnClickOutside } from '@/hooks/useOnClickOutside'
import { useOnScroll } from '@/hooks/useOnScroll'

export const GeocodeInput = ({
  callback = () => null,
  lat,
  lng,
}: {
  callback: (data: any) => any
  lat: any
  lng: any
}) => {
  const ref = useRef()
  const inputRef = useRef<HTMLInputElement>()
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [searchResults, setSearchResults] = useState([])

  const [fieldOpen, setFieldOpen] = useState(false)

  useOnClickOutside(ref, () => setFieldOpen(false))
  useOnScroll(ref, () => setFieldOpen(false))

  useEffect(() => {
    if (inputValue.length) {
      setFieldOpen(true)
    } else {
      setFieldOpen(false)
    }
  }, [inputValue])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value)
    queryGeocode(value)
  }

  const queryGeocode = useDebouncedCallback(async (query: string) => {
    setLoading(true)
    geocodingClient
      .forwardGeocode({
        query: query,
        types: ['address', 'postcode', 'country', 'neighborhood', 'region', 'district', 'locality'],
        limit: 25,
        countries: ['ca', 'us'],
      })
      .send()
      .then((response) => {
        setSearchResults(response.body.features)
        setLoading(false)
      })
  }, 500)

  const handleSelectClick = (option) => {
    setInputValue(option.place_name)
    setFieldOpen(false)
    setSearchResults([])
    callback({
      streetNumber: option.address,
      streetName: option.text,
      neighbourhood: option.context.find((x) => x.id.startsWith('neighborhood'))?.text || '',
      region: option.context.find((x) => x.id.startsWith('region'))?.short_code.substring(3) || '',
      city: option.context.find((x) => x.id.startsWith('place'))?.text || '',
      postalZip: option.context.find((x) => x.id.startsWith('postcode'))?.text || '',
      country: option.context.find((x) => x.id.startsWith('country'))?.text || '',
      latitude: option.center[1],
      longitude: option.center[0],
    })
  }

  const clearInput = () => {
    setInputValue('')
    setFieldOpen(false)
    setSearchResults([])
    callback({})
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <div className="relative group">
      <label
        htmlFor="addressSearch"
        className={clsx(
          'block font-serif font-bold leading-normal text-base absolute z-10 top-0 left-4  group-focus-within:translate-y-2 group-focus-within:!text-sm transition-all pointer-events-none text-black dark:text-white',
          inputValue ? 'translate-y-2 !text-sm' : 'translate-y-[16px]',
        )}
      >
        Search for the address.
      </label>
      <div className="relative">
        <input
          ref={inputRef}
          type="search"
          name="addressSearch"
          value={inputValue}
          onChange={handleInputChange}
          className={clsx(
            'border-2 transition   border-zinc-200 dark:border-zinc-700/60  dark:bg-zinc-800 bg-white  font-sans appearance-none block w-full  rounded-lg    placeholder-zinc-400 dark:placeholder-zinc-400/80   peer relative z-[1] font-medium  selection:bg-black text-black dark:text-white focus:ring-1 focus:ring-offset-1 placeholder:font-serif placeholder:font-regular  focus:outline focus:outline-brand  focus:outline-2 focus:outline-offset-0 focus:ring-transparent  text-base caret-brand  peer shadow-subtler dark:shadow-lg dark:shadow-zinc-800/70 pt-6 pb-2 px-4 pr-16 truncate',
          )}
        />

        {loading ? (
          <span className="absolute h-3 w-3 top-6 right-4 z-10 fill-brand-500">
            <Loader />
          </span>
        ) : inputValue.length ? (
          <button
            className="absolute h-8 w-8 top-3 right-4 z-10 bg-brand rounded-full  flex justify-center items-center shadow-subtler"
            type="button"
            onClick={clearInput}
          >
            <Cross className="fill-white h-2 w-2" />
            <span className="sr-only">Clear</span>
          </button>
        ) : (
          ''
        )}
      </div>
      <AnimatePresence>
        {fieldOpen && searchResults.length ? (
          <motion.div
            initial={{ y: '-50px', opacity: 0 }}
            exit={{ y: '-50px', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            ref={ref}
            className="top-20 absolute z-[90] w-full border-1 border-black/10 dark:border-zinc-500/20 dark:bg-zinc-800/80 backdrop-blur-lg bg-snow/80 divide-y divide-camel rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-scroll p-2 h-72"
          >
            <ul>
              {searchResults.map((result) => (
                <li key={result.id} className="">
                  <button
                    onClick={() => handleSelectClick(result)}
                    type="button"
                    className="cursor-pointer select-none rounded-md px-3 py-5 block dark:text-white text-black hover:text-white hover:dark:text-white hover:dark:bg-brand hover:bg-brand w-full text-left  tracking-tight font-medium leading-none"
                  >
                    {result.place_name}
                  </button>
                </li>
              ))}
            </ul>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  )
}

const Cross = ({ className }: { className?: string }) => (
  <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M9.2608 7.49893L14.6306 2.14085C14.8658 1.90566 14.9979 1.58668 14.9979 1.25408C14.9979 0.921478 14.8658 0.602498 14.6306 0.367312C14.3955 0.132126 14.0765 0 13.744 0C13.4114 0 13.0925 0.132126 12.8573 0.367312L7.5 5.73789L2.14268 0.367312C1.90752 0.132126 1.58859 -2.47808e-09 1.25603 0C0.923478 2.47809e-09 0.604543 0.132126 0.369391 0.367312C0.134239 0.602498 0.00213119 0.921478 0.00213119 1.25408C0.00213119 1.58668 0.134239 1.90566 0.369391 2.14085L5.7392 7.49893L0.369391 12.857C0.252343 12.9731 0.159441 13.1113 0.0960411 13.2635C0.0326416 13.4157 0 13.5789 0 13.7438C0 13.9087 0.0326416 14.0719 0.0960411 14.2241C0.159441 14.3763 0.252343 14.5144 0.369391 14.6306C0.485482 14.7476 0.6236 14.8405 0.775777 14.9039C0.927954 14.9674 1.09118 15 1.25603 15C1.42089 15 1.58411 14.9674 1.73629 14.9039C1.88847 14.8405 2.02659 14.7476 2.14268 14.6306L7.5 9.25998L12.8573 14.6306C12.9734 14.7476 13.1115 14.8405 13.2637 14.9039C13.4159 14.9674 13.5791 15 13.744 15C13.9088 15 14.072 14.9674 14.2242 14.9039C14.3764 14.8405 14.5145 14.7476 14.6306 14.6306C14.7477 14.5144 14.8406 14.3763 14.904 14.2241C14.9674 14.0719 15 13.9087 15 13.7438C15 13.5789 14.9674 13.4157 14.904 13.2635C14.8406 13.1113 14.7477 12.9731 14.6306 12.857L9.2608 7.49893Z"
      fill="current"
    />
  </svg>
)

const Loader = () => (
  <svg
    className="animate-spin -ml-1 mr-3 h-5 w-5 text-zinc-800"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
)
