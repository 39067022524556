import { Range, getTrackBackground } from 'react-range'
import { useController } from 'react-hook-form'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'
import NumberFormat from 'react-number-format'
import { Label } from '@/components'
import { useDarkMode } from '@/hooks/useDarkMode'

interface RangeSliderProps {
  label: string
  values: number
  className?: string
  description?: string
  error?: string
  min?: number
  max?: number
  step?: number
  name: string
  displayFormat?: string
  toFixedValue?: number
  disabled?: boolean
  onChange: (event) => void
  displayPrice?: boolean
}

export const RangeSlider = ({
  min = 0,
  max = 10000,
  onChange = () => null,
  values = 10,
  step = 1,
  label = '',
  error = '',
  name = '',
  description = '',
  displayFormat = '',
  toFixedValue = 1,
  displayPrice = false,
  disabled = false,
  className,
}: RangeSliderProps) => {
  const [mode] = useDarkMode()
  return (
    <div
      className={clsx(disabled ? 'opacity-20 saturate-50' : '', displayPrice ? 'space-y-3' : 'space-y-3', className)}
    >
      <Label name={name} description={description} error={error} label={label} />
      <div className={clsx('flex items-center')}>
        {displayPrice ? (
          <div className="relative">
            <NumberFormat
              value={Array.isArray(values) ? values[0].toFixed(2) : values.toFixed(2)}
              thousandSeparator={true}
              allowNegative={false}
              min={min}
              max={max}
              disabled={disabled}
              onValueChange={(target) => {
                if (target.floatValue) {
                  onChange(target.floatValue)
                }
              }}
              isNumericString
              prefix="$ "
              className={clsx(
                'peer shadow-md dark:shadow-black/20 shadow-zinc-200/60 font-sans appearance-none block w-36 px-3 pt-3.5 pb-4 border rounded dark:bg-zinc-900 bg-white text-base focus:outline-none placeholder-zinc-400 focus:ring-1 caret-brand-500  relative z-[1]',
                error
                  ? 'border-red-600 focus:ring-rose-600 focus:border-rose-600 text-rose-600'
                  : 'dark:border-zinc-500 border-zinc-600/30  dark:text-neutral-50 text-zinc-700 focus:dark:border-transparent focus:border-transparent focus:ring-transparent',
                disabled ? 'bg-zinc-100' : 'bg-white',
              )}
            />
            <span className='hidden peer-focus:block after:content-[""] after:absolute after:-inset-[2px] after:bg-gradient-to-r after:from-brand-700 after:via-teal-400 after:to-yellow-500 after:dark:to-amber-600 after:rounded-lg -z-[1] after:pointer-events-none' />
          </div>
        ) : null}
        <div className="flex flex-1 justify-center flex-wrap px-6">
          <Range
            step={step}
            min={min}
            max={max}
            draggableTrack
            values={[values]}
            disabled={disabled}
            onChange={(value) => onChange(value[0])}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                className="flex w-full h-6 rounded-full"
                style={{
                  ...props.style,
                }}
              >
                <div
                  ref={props.ref}
                  className="h-3 w-full rounded-full border dark:border-zinc-500  shadow-inner"
                  style={{
                    background: getTrackBackground({
                      values: [values],
                      colors:
                        [values].length > 1 ? ['#fff', '#6366f1', '#fff'] : ['#6366f1', mode ? '#18181b' : '#fff'],
                      min: min,
                      max: max,
                    }),
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props }) => {
              return (
                <div
                  {...props}
                  className="h-6 w-6 rounded-full border border-gray-600/50 dark:dark:border-zinc-500 bg-white dark:bg-zinc-300  shadow justify-center items-center flex focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-brand-500 active:ring-1 active:ring-offset-1 active:ring-brand-500"
                  style={{
                    ...props.style,
                  }}
                >
                  <ReactSVG src="/svg/drag.svg" className="relative dark:fill-zinc-90 fill-zinc-500" wrapper="span" />
                  <div className="shadow-sm absolute -bottom-9 dark:bg-zinc-700 text-white text-xs pt-2 pb-1 px-1.5 bg-zinc-900 rounded-md min-w-[28px] flex justify-center">
                    {`${displayFormat}${
                      Array.isArray(values) ? values[0].toFixed(toFixedValue) : values.toFixed(toFixedValue)
                    }`}
                  </div>
                </div>
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}

RangeSlider.displayName = 'RangeSlider'

interface ControlledRangeSliderProps {
  label: string
  name: string
  rules?: any
  className?: string
  control?: any
  options?: any[]
  description?: string
  min?: number
  max?: number
  step?: number
  defaultValue?: number
  displayFormat?: string
  toFixedValue?: number
  displayPrice?: boolean
  disabled?: boolean
}

export const ControlledRangeSlider = ({
  label,
  control,
  name,
  rules,
  description,
  min = 0,
  max = 100,
  step = 0.1,
  defaultValue = 50,
  displayFormat = '',
  toFixedValue = 0,
  displayPrice = false,
  disabled = false,
  className,
}: ControlledRangeSliderProps) => {
  const {
    field: { onChange, name: inputName, value },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })

  return (
    <RangeSlider
      className={className}
      label={label}
      onChange={onChange}
      values={value}
      description={description}
      min={min}
      max={max}
      step={step}
      name={inputName}
      displayFormat={displayFormat}
      toFixedValue={toFixedValue}
      displayPrice={displayPrice}
      disabled={disabled}
    />
  )
}
