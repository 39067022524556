import React from 'react'
import clsx from 'clsx'
import { useController } from 'react-hook-form'
import { Switch } from '@headlessui/react'
import { AnimatePresence, motion } from 'framer-motion'

interface ToggleProps {
  label: string
  name: string
  value: boolean
  className?: string
  description?: string
  disabled?: boolean
  onChange: ((checked: boolean) => void) | (React.FormEventHandler<HTMLButtonElement> & ((checked: boolean) => void))
  stacked?: boolean
  large?: boolean
  reverse?: boolean
}

export const Toggle: React.FC<ToggleProps> = ({
  label,
  name = '',
  value = false,
  description = '',
  disabled = false,
  onChange = () => null,
  className,
  stacked = false,
  large = false,
  reverse = false,
}: ToggleProps) => {
  return (
    <Switch.Group
      as="div"
      className={clsx(
        ' items-start flex transition duration-200 ease-in-out',
        stacked ? 'space-y-2 flex-col' : 'flex-row',
        disabled ? 'opacity-50 saturate-50 blur-[2px] select-none pointer-events-none' : '',
        className,
      )}
    >
      <span
        className={clsx(
          'flex items-start flex-col',
          stacked ? '' : reverse ? 'order-2 ml-4' : ' mr-4',
          description ? 'mt-1' : 'mt-0.5',
        )}
      >
        <Switch.Label
          as="span"
          className={clsx('inline font-serif text-black dark:text-white text-base font-bold', description ? '' : '')}
        >
          {label}
        </Switch.Label>
        {description && (
          <Switch.Description
            as="span"
            className="mb-1.5 font-sans text-sm dark:text-white/80 text-black/80 font-semibold first-letter:capitalize"
          >
            {description}
          </Switch.Description>
        )}
      </span>
      <div className="relative">
        <Switch
          name={name}
          checked={value}
          onChange={onChange}
          disabled={disabled}
          className={clsx(
            'relative rounded-full border-2 border-zinc-200 dark:border-zinc-600 shadow-input focus:outline focus:outline-offset-2 focus:outline-brand focus:dark:outline-brand focus:outline-2  focus:ring-transparent',
          )}
        >
          <div
            className={clsx(
              large ? 'h-8 w-14' : 'h-7 w-12',
              disabled ? 'opacity-50' : 'opacity-100',
              'border-zinc-400/50 dark:border-zinc-600/60  dark:bg-zinc-800 bg-snow',
              'relative rounded-full inline-flex  flex-shrink-0 cursor-pointer  pt-0.5 transition duration-200  ease-in-out focus:outline focus:outline-brand focus:dark:outline-brand focus:outline-2 focus:outline-offset-0 focus:ring-transparent z-10 dark:shadow-subtle shadow-subtler',
            )}
          >
            <span
              aria-hidden="true"
              className={clsx(
                large ? 'h-6 w-6' : 'h-5 w-5',
                value
                  ? 'text-white dark:text-white bg-brand dark:bg-brand'
                  : 'text-white dark:text-zinc-100 bg-zinc-600 dark:bg-snow',
                value ? (large ? 'translate-x-7' : 'translate-x-6') : large ? 'translate-x-1' : 'translate-x-1',
                'pointer-events-none inline-flex translate-y-[2px] transform items-center justify-center rounded-full border  border-zinc-400/50 dark:border-zinc-600/60 ring-0 transition duration-200 ease-in-out shadow-input ',
              )}
            >
              {value ? (
                <AnimatePresence>
                  <motion.svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    initial={{ scale: 0, opacity: 0, rotate: -90 }}
                    exit={{ scale: 0, opacity: 0, rotate: -90 }}
                    animate={{ scale: 1, opacity: 1, rotate: 0 }}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                  </motion.svg>
                </AnimatePresence>
              ) : null}
            </span>
          </div>
        </Switch>
      </div>
    </Switch.Group>
  )
}

Toggle.displayName = 'Toggle'

interface ControlledSelectProps {
  label: string
  name: string
  rules?: any
  className?: string
  control?: any
  options?: any[]
  description?: string
  disabled?: boolean
  stacked?: boolean
  large?: boolean
  reverse?: boolean
}

export const ControlledToggle = ({
  label,
  control,
  name,
  rules,
  className,
  description,
  disabled = false,
  stacked = false,
  large = false,
  reverse = false,
}: ControlledSelectProps) => {
  const {
    field: { onChange, name: inputName, value },
  } = useController({
    name,
    control,
    defaultValue: false,
  })

  return (
    <Toggle
      stacked={stacked}
      large={large}
      className={className}
      label={label}
      onChange={onChange}
      value={value}
      name={inputName}
      description={description}
      disabled={disabled}
      reverse={reverse}
    />
  )
}
