import { useEffect } from 'react'
import { useSession } from 'next-auth/react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { gql } from 'apollo-server-micro'

const useFavourite = () => {
  const { data: session } = useSession()
  const [fetchFavs, { data: favourites, loading, error }] = useLazyQuery(gql`
    query ActiveUser {
      activeUser {
        favourites {
          id
          listingType
          unitNumber
          streetNumber
          streetName
          city
          coordinates
          country
          region
          neighbourhood
          postalzip
          forRent
          latitude
          longitude
          shortcode
          normalized_address
          updatedAt
          createdAt
          rentals {
            id
            active
          }
          reviews {
            id
            rating
            active
            content
          }
        }
      }
    }
  `)

  const [addFavMutation, { data: addFavMutationData, loading: addFavMutationLoading, error: addFavMutationError }] =
    useMutation(
      gql`
        mutation AddListingToFavourites($listingId: String!) {
          addListingToFavourites(listingId: $listingId) {
            id
          }
        }
      `,
      {
        refetchQueries: ['ActiveUser'],
      },
    )

  const [
    removeFavMutation,
    { data: removeFavMutationData, loading: removeFavMutationLoading, error: removeFavMutationError },
  ] = useMutation(
    gql`
      mutation RemoveListingFromFavourites($listingId: String!) {
        removeListingFromFavourites(listingId: $listingId) {
          id
        }
      }
    `,
    {
      refetchQueries: ['ActiveUser'],
    },
  )

  useEffect(() => {
    if (session) {
      fetchFavs()
    }
  }, [session, fetchFavs])

  const addFavourite = async (id: string) => {
    addFavMutation({
      variables: {
        listingId: id,
      },
    })
  }

  const removeFavourite = async (id: string) => {
    removeFavMutation({
      variables: {
        listingId: id,
      },
    })
  }

  return {
    favourites: favourites?.activeUser.favourites.map((fav) => fav.id) || [],
    favouritesListings: favourites?.activeUser.favourites || [],
    addFavourite,
    removeFavourite,
    favLoaded: !loading,
  }
}

export default useFavourite
