import { SetState } from 'zustand'
import { MyState } from '@/store'

export interface SearchState {
  searchActive: boolean
  searchValue: string
  setSearchValue: (value: string) => void
  clearSearchValue: () => void
  openSearch: () => void
  closeSearch: () => void
}

const createSearchState = (set: SetState<MyState>) => ({
  searchActive: false,
  searchValue: '',
  setSearchValue: (value: string) => {
    set(() => ({ searchValue: value }))
  },
  clearSearchValue: () => set(() => ({ searchValue: '' })),
  openSearch: () => set(() => ({ searchActive: true })),
  closeSearch: () => set(() => ({ searchActive: false })),
})

export default createSearchState
