import React from 'react'
import clsx from 'clsx'
import { useController } from 'react-hook-form'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { Label } from '@/components'

interface InputProps {
  label: string
  name: string
  value: any
  required?: boolean
  className?: string
  description?: string
  error?: string
  options: any[]
  hideLabel?: boolean
  disabled?: boolean
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export const Select: React.FC<InputProps> = ({
  label,
  name = '',
  value = '',
  required = false,
  description = '',
  error = '',
  options = [],
  disabled = false,
  hideLabel = false,
  onChange = () => null,
  className,
}: InputProps) => {
  return (
    <div
      className={clsx(
        'space-y-3 transition duration-200 ease-in-out relative',
        disabled ? 'opacity-50 saturate-50 blur-[2px] select-none pointer-events-none' : '',
        className,
      )}
    >
      <Label
        name={name}
        description={description}
        error={error}
        label={label}
        className={clsx('mb-2', hideLabel && 'sr-only')}
      />
      <div className="relative ">
        <select
          id={name}
          onChange={onChange}
          onBlur={onChange}
          name={name}
          required={required}
          value={value}
          aria-invalid={!!error}
          aria-disabled={disabled}
          disabled={disabled}
          aria-describedby={`${name}-description`}
          className={clsx(
            'border-2 transition   border-zinc-200 dark:border-zinc-700/60  dark:bg-zinc-800 bg-white  font-sans appearance-none block w-full  rounded-lg    placeholder-zinc-400 dark:placeholder-zinc-400/80   peer relative z-[1] font-medium  selection:bg-black text-black dark:text-white focus:ring-1 focus:ring-offset-1 placeholder:font-serif placeholder:font-regular  focus:outline focus:outline-brand  focus:outline-2 focus:outline-offset-0 focus:ring-transparent  text-base caret-brand  peer shadow-subtler dark:shadow-lg dark:shadow-zinc-800/70 p-3 dark:bg-select-chevron-dark bg-select-chevron bg-no-repeat bg-[center_right_1rem] ',
            error ? 'border-red-600 focus:ring-rose-600 focus:border-rose-600 text-rose-600' : '',
            disabled ? 'bg-zinc-100' : '',
          )}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-rose-600" aria-hidden="true" />
          </div>
        )}
      </div>

      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-description`}>
          {error}
        </p>
      )}
    </div>
  )
}

Select.displayName = 'Select'

interface ControlledSelectProps {
  label: string
  name: string
  rules?: any
  className?: string
  control?: any
  options?: any[]
  disabled?: boolean
}

export const ControlledSelect = ({
  label,
  control,
  name,
  rules,
  className,
  options = [],
  disabled = false,
}: ControlledSelectProps) => {
  const {
    field: { onChange, name: inputName, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: '',
  })

  return (
    <Select
      className={className}
      label={label}
      onChange={onChange}
      value={value}
      name={inputName}
      error={error?.message || error?.type === 'required' ? 'Required' : ''}
      options={options}
      disabled={disabled}
    />
  )
}
