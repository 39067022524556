interface Props {
  unitNumber?: string | null
  streetNumber: string
  streetName: string
  city: string
  region: string
  country: string
  postalzip?: string
  postalZip?: string
}

export default function formatAddress({
  unitNumber,
  streetNumber = '',
  streetName = '',
  city = '',
  region = '',
  country = '',
  postalzip = '',
  postalZip = '',
}: Props): string {
  return (
    (unitNumber ? `${unitNumber} - ` : '') +
    `${streetNumber} ${streetName}, ${city} ${region}, ${country.charAt(0).toUpperCase() + country.slice(1)} ${
      postalzip || postalZip
    }`
  )
}
