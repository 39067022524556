import { format } from 'date-fns'
import { Badge, Button, Title, DetailIcon, Card, ButtonLink } from '@/components'
import { motion } from 'framer-motion'
import { useState } from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import { useSession } from 'next-auth/react'
import { FaBed, FaShower } from 'react-icons/fa'
import { Carousel } from 'react-responsive-carousel'
import { Showings } from './Showings'
import { ForRentMessage } from './ForRentMessage'

const myLoader = ({ src }) => {
  return `${process.env.NEXT_PUBLIC_IMAGEKIT_URL_ENDPOINT}/${src}`
}

export const ForRentCard = (activeRental: any) => {
  const [isOpen, setIsOpen] = useState(false)
  const [messageOpen, setMessageOpen] = useState(false)
  const { data } = useSession()

  return (
    <div className="mb-6">
      <Card feature borderColor="border-brand-200 dark:border-lime-300">
        <div className="mb-4 mt-2">
          <Badge>For Rent</Badge>
        </div>
        <Title className="mb-2 text-zinc-500 dark:text-zinc-300 font-medium">
          Available: {format(new Date(activeRental.availability), 'MMMM d, yyyy')}
        </Title>
        <p className="text-3xl font-extrabold tracking-tight">${activeRental.rentPrice}/Monthly</p>
        {activeRental.images.length ? (
          <div className="my-6 rounded-md overflow-hidden">
            <Carousel dynamicHeight centerMode>
              {activeRental.images?.map((image, idx) => (
                <div key={image.fileName} className="relative">
                  <div className="overflow-hidden shadow-subtle h-[500px]">
                    <Image
                      loader={myLoader}
                      width="830"
                      height="500"
                      src={`tr:w-830,h-500,q-80${image.filePath}`}
                      className="mb-0 leading-none"
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        ) : null}
        <ul className="grid lg:grid-cols-4 grid-cols-2 sm:grid-cols-3 gap-4 mb-6 mt-4">
          <li>
            <p className="text-base text-zinc-500 dark:text-zinc-400 tracking-tight font-medium mb-0.5">Bedrooms.</p>
            <p className="capitalize font-sans rounded outline-transparent tracking-wide font-semibold inline-flex items-center text-zinc-600 dark:text-zinc-300">
              <span className="relative -top-px  inline-block mr-2">
                <FaBed className="w-5 h-5 " aria-hidden />
              </span>{' '}
              {activeRental.bedrooms}
            </p>
          </li>
          <li>
            <p className="text-base text-zinc-500 dark:text-zinc-400 tracking-tight font-medium mb-0.5">Bathrooms.</p>
            <p className="capitalize font-sans rounded outline-transparent tracking-wide font-semibold inline-flex items-center text-zinc-600 dark:text-zinc-300">
              <span className="relative -top-px  inline-block mr-1.5">
                <FaShower className="w-5 h-5" aria-hidden />
              </span>
              {activeRental.bathrooms}
            </p>
          </li>
        </ul>
        <div
          className="text-base text-zinc-600 dark:text-zinc-100 prose "
          dangerouslySetInnerHTML={{ __html: activeRental.description }}
        />

        <div className="relative">
          <motion.div
            className="mt-8"
            initial={{ height: '100px', overflowY: 'hidden' }}
            animate={isOpen ? 'open' : 'closed'}
            variants={{
              open: { opacity: 1, height: 'auto', overflowY: 'visible' },
              closed: { opacity: 1, height: '100px', overflowY: 'hidden' },
            }}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
              <DetailIcon icon="gas" theme="bg-green-200" iconClass="stroke-green-800" name="gas">
                {activeRental.details?.gasIncluded || activeRental.details?.gasAvgPrice === 0
                  ? 'Gas Included'
                  : `Gas approx $${activeRental.details?.gasAvgPrice} per month`}
              </DetailIcon>

              <DetailIcon icon="water" theme="bg-indigo-200" iconClass="stroke-indigo-800" name="water">
                {activeRental.details?.waterIncluded || activeRental.details?.waterAvgPrice === 0
                  ? 'Water Included'
                  : `Water approx $${activeRental.details?.waterAvgPrice} per month`}
              </DetailIcon>

              <DetailIcon icon="hydro" theme="bg-orange-200" iconClass="stroke-orange-800" name="hydro">
                {activeRental.details?.hydroIncluded || activeRental.details?.hydroAvgPrice === 0
                  ? 'Electricity Included'
                  : `Electricity approx $${activeRental.details?.hydroAvgPrice} per month`}
              </DetailIcon>

              <DetailIcon icon="dishwasher" theme="bg-green-200" iconClass="stroke-green-800" name="dishwasher">
                {activeRental.details?.dishwasherAvailable ? 'Dishwasher Included' : 'No dishwasher'}
              </DetailIcon>

              <DetailIcon icon="airconditioner" theme="bg-brand-50" iconClass="stroke-brand-800" name="airconditioner">
                {activeRental.details?.airConditioningIncluded ? 'Air Conditioning Included' : 'No air conditioning'}
              </DetailIcon>

              <DetailIcon icon="storage" theme="bg-amber-200" iconClass="text-amber-800" name="storage">
                {activeRental.storageAvailable ? 'Storage available' : 'No storage'}
              </DetailIcon>

              <DetailIcon
                icon="controlled_access"
                theme="bg-orange-200"
                iconClass="stroke-orange-800"
                name="controlled access"
              >
                {activeRental.controlledAccess ? 'Controlled Access available' : 'No Controlled Access'}
              </DetailIcon>

              <DetailIcon icon="outdoor_space" theme="bg-rose-200" iconClass="stroke-rose-800" name="outdoor space">
                {activeRental.outdoorSpace ? 'Outdoor Space available' : 'No outdoor space'}
              </DetailIcon>

              <DetailIcon icon="balcony" theme="bg-emerald-200" iconClass="stroke-emerald-800" name="balcony">
                {activeRental.balcony ? 'Balcony available' : 'No balcony'}
              </DetailIcon>

              <DetailIcon icon="parking" theme="bg-emerald-200" iconClass="stroke-emerald-800" name="parking">
                {activeRental.details?.parkingLocation === 'none'
                  ? 'No parking available'
                  : activeRental.details?.parkingLocation.charAt(0).toUpperCase() +
                    activeRental.details?.parkingLocation.slice(1)}
                {activeRental.details?.parkingLocation !== 'none' && activeRental.details?.parkingAvgPrice
                  ? `: approx $${activeRental.details?.parkingAvgPrice} per month`
                  : null}
              </DetailIcon>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mt-10">
              <DetailIcon
                icon="laundry"
                theme="bg-lime-200"
                iconClass="stroke-lime-800"
                name="laundry"
                metrics={
                  activeRental.details?.laundryLocation !== 'none' ? (
                    <>
                      <p className="text-sm mt-0.5 prose text-zinc-500 dark:text-zinc-400">
                        {activeRental.details?.laundryAvgPrice
                          ? `Washing Machine $${activeRental.details?.laundryAvgPrice} per load`
                          : `Washing Machine included`}
                      </p>
                      <p className="text-sm mt-1 prose text-zinc-500 dark:text-zinc-400">
                        {activeRental.details?.dryerAvgPrice
                          ? `Dryer $${activeRental.details?.dryerAvgPrice} per load`
                          : `Dryer included`}
                      </p>
                    </>
                  ) : null
                }
              >
                {activeRental.details?.laundryLocation === 'none'
                  ? 'No laundry available'
                  : `Laundry available in ${activeRental.details?.laundryLocation}`}
              </DetailIcon>

              <DetailIcon
                icon="pets"
                theme="bg-yellow-200"
                iconClass="stroke-yellow-800"
                name="pets"
                metrics={
                  activeRental.details?.petsWelcomeStory ? (
                    <p className="text-sm mt-1 prose text-zinc-500 dark:text-zinc-400">
                      {activeRental.details?.petsWelcomeStory}
                    </p>
                  ) : null
                }
              >
                {activeRental.details?.petsWelcome ? 'Pets are welcome' : 'No pets welcome'}
              </DetailIcon>

              <DetailIcon
                icon="accessibility"
                theme="bg-lime-200"
                iconClass="stroke-lime-800"
                name="accessibility"
                metrics={
                  activeRental.details?.accessibilityStory ? (
                    <p className="text-sm mt-1 prose text-zinc-500 dark:text-zinc-400">
                      {activeRental.details?.accessibilityStory}
                    </p>
                  ) : null
                }
              >
                {activeRental.details?.accessibility ? 'Accessibility Friendly.' : 'Not Accessiblity Friendly.'}
              </DetailIcon>

              <DetailIcon
                icon="languages"
                theme="bg-rose-200"
                iconClass="stroke-rose-800"
                name="languages"
                metrics={
                  activeRental.details?.landlordLanguagesStory ? (
                    <p className="text-sm mt-1 prose text-zinc-500 dark:text-zinc-400">
                      {activeRental.details?.landlordLanguagesStory}
                    </p>
                  ) : null
                }
              >
                {activeRental.details?.landlordLanguages
                  ? 'Landlord supports other languages.'
                  : 'Landlord does not support other languages.'}
              </DetailIcon>
            </div>
            <motion.div
              animate={isOpen ? 'open' : 'closed'}
              variants={{
                open: { opacity: 0, backdropFilter: 'blur(0)' },
                closed: { opacity: 1, backdropFilter: 'blur(4px)' },
              }}
              className={clsx(
                'absolute  -inset-1 bg-gradient-to-b dark:from-zinc-900/50 dark:to-zinc-900 from-white/50 to-white',
                isOpen ? '' : ' backdrop-blur-[1px]',
              )}
            />
          </motion.div>
          {!isOpen && (
            <motion.button
              whileHover={{
                top: '52%',
                transition: { duration: 0.5 },
              }}
              onClick={() => setIsOpen(true)}
              className=" top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 px-6 py-1 dark:bg-zinc-900 border dark:border-zinc-700 bg-white absolute shadow-subtle rounded-full text-xs font-medium flex items-center"
            >
              Show more
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="-mr-1 ml-1 h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
              </svg>
            </motion.button>
          )}
        </div>

        {data?.user.id === activeRental.userId ? (
          <div className="mt-8 mb-4 pt-8 border-t border-zinc-200 dark:border-zinc-700">
            <Button
              outline
              internal
              small
              url={`/rentals/manage/${activeRental.id}`}
              withIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-ml-1 mr-3 h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                  />
                </svg>
              }
            >
              Manage Listing
            </Button>
          </div>
        ) : (
          <div className="space-x-6 mt-8 mb-4 pt-8 border-t border-zinc-200 dark:border-zinc-700">
            <Button
              small
              onClick={() => setMessageOpen(true)}
              withIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-ml-1 mr-3 h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                  />
                </svg>
              }
            >
              Message
            </Button>
            {activeRental.canEmail ? (
              <Button
                url={`mailto:${activeRental.email}`}
                small
                color="bg-emerald-600 hover:bg-emerald-700 dark:border-emerald-600 border-emerald-600 hover:dark:border-emerald-700 focus:ring-emerald-500"
                withIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="-ml-1 mr-3 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                }
              >
                Email
              </Button>
            ) : null}
            {activeRental.canPhone ? (
              <Button
                url={`tel:${activeRental.phoneNumber}`}
                small
                color="bg-cyan-600 hover:bg-cyan-700 dark:border-cyan-600 border-cyan-600 hover:dark:border-cyan-700 focus:ring-cyan-500"
                withIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="-ml-1 mr-3 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 3l-6 6m0 0V4m0 5h5M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
                    />
                  </svg>
                }
              >
                Contact by phone
              </Button>
            ) : null}
          </div>
        )}
        {activeRental.showings?.length && data?.user.id !== activeRental.userId ? (
          <Showings rentalId={activeRental.id} />
        ) : null}
      </Card>
      <ForRentMessage recipient={activeRental.userId} open={messageOpen} setOpen={setMessageOpen} />
    </div>
  )
}
