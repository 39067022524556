import { enUS } from 'date-fns/locale'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { parse, format, isAfter, isBefore, addDays, subDays, isValid } from 'date-fns'

import { AnimatePresence, motion } from 'framer-motion'
import { useController } from 'react-hook-form'
import { FormattedInput } from '@buttercup/react-formatted-input'
import { DatePickerCalendar, useDateInput, START_DATE, END_DATE } from '@axel-dev/react-nice-dates'
import { Label } from '@/components'
import { CalendarIcon } from '@heroicons/react/outline'
import { useOnClickOutside } from '@/hooks/useOnClickOutside'
import { useOnScroll } from '@/hooks/useOnScroll'

interface InputProps {
  label: string
  name: string
  value?: Date
  className?: string
  description?: string
  error?: string
  placeholder?: string
  numberOfMonths?: number
  disabled?: boolean
  maxDate?: Date
  minDate?: Date
  onChange?: (day: Date) => void
}

export const DateInput: React.FC<InputProps> = ({
  label,
  name = '',
  value,
  numberOfMonths = 1,
  className = '',
  description,
  error = '',
  disabled = false,
  maxDate = null,
  minDate = null,
  onChange = () => null,
}: InputProps) => {
  const ref = useRef()
  const button = useRef()
  const [date, setDate] = useState(new Date())
  const [formattedInput, setFormattedInput] = useState<any>()
  const [showCalendar, setShowCalendar] = useState(false)
  const [inputError, setInputError] = useState('')

  useOnClickOutside(ref, (event) => {
    if (showCalendar) {
      setShowCalendar(false)
    }
  })
  useOnScroll(ref, () => {
    if (showCalendar) {
      setShowCalendar(false)
    }
  })

  useEffect(() => {
    if (isValid(value)) {
      setDate(value)
      setFormattedInput(format(value, 'MM/dd/yyyy'))
    }
  }, [value, setDate])

  const handleInputFormat = (formattedValue, raw) => {
    console.log(formattedValue)
    const dateVal = parse(formattedValue, 'MM/dd/yyyy', new Date())
    setFormattedInput(formattedValue)

    if (minDate && isBefore(dateVal, subDays(minDate, 1))) {
      setDate(new Date())
      setInputError('Date is too early')
    } else if (maxDate && isAfter(dateVal, addDays(maxDate, 1))) {
      setDate(new Date())
      setInputError('Date is too late')
    } else {
      setInputError('')
      setFormattedInput(formattedValue)
      setDate(dateVal)
      onChange(dateVal)
    }
  }

  const handleCalChange = (val) => {
    setInputError('')
    setDate(val)
    const dateVal = format(val, 'MM/dd/yyyy')
    setFormattedInput(dateVal)
  }

  const DateFormat = [
    { char: /[01]/, repeat: 1 },
    { char: /\d/, repeat: 1 },
    { exactly: '/' },
    { char: /[0-3]/, repeat: 1 },
    { char: /\d/, repeat: 1 },
    { exactly: '/' },
    { char: /2/, repeat: 1 },
    { char: /\d/, repeat: 3 },
  ]

  return (
    <div
      className={clsx(
        'transition duration-200 ease-in-out relative',
        disabled ? 'opacity-50 saturate-50 blur-[2px] select-none pointer-events-none' : '',
        className,
      )}
    >
      <Label name={name} description={description} label={label} className="mb-2" error={error || inputError} />
      <div className="relative inline-block w-full">
        <FormattedInput
          value={formattedInput}
          onChange={handleInputFormat}
          format={DateFormat}
          placeholder="mm/dd/yyyy"
          className={clsx(
            'border-2 transition   border-zinc-200 dark:border-zinc-700/60  dark:bg-zinc-800 bg-white  font-sans appearance-none block w-full  rounded-lg    placeholder-zinc-400 dark:placeholder-zinc-400/80    peer relative z-[1] font-medium  selection:bg-black text-black dark:text-white focus:ring-1 focus:ring-offset-1 placeholder:font-serif placeholder:font-regular  focus:outline focus:outline-brand  focus:outline-2 focus:outline-offset-0 focus:ring-transparent  text-base caret-brand  peer shadow-subtler dark:shadow-lg dark:shadow-zinc-800/70 p-3',
            error || inputError ? 'border-red-600 focus:ring-rose-600 focus:border-rose-600 text-rose-600' : '',
          )}
        />
        <button
          type="button"
          onClick={() => {
            if (!showCalendar) setShowCalendar(true)
          }}
          className="absolute top-1/2 -translate-y-1/2 z-10 right-3"
        >
          <CalendarIcon
            className={clsx('h-5 w-5 text-zinc-800 dark:text-zinc-200', (error || inputError) && 'text-rose-600')}
          />
        </button>
      </div>
      <AnimatePresence>
        {showCalendar && (
          <motion.div
            className="absolute w-1/2 right-2 top-10 min-w-[20rem] z-20 bg-snow dark:bg-[#111] rounded-md border border-zinc-200 dark:border-zinc-700/60 "
            initial={{ y: '-50px', opacity: 0 }}
            exit={{ y: '-50px', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            key="calendar"
            ref={ref}
          >
            <DatePickerCalendar
              date={date}
              onDateChange={handleCalChange}
              locale={enUS}
              minimumDate={minDate}
              maximumDate={maxDate}
            />
          </motion.div>
        )}
      </AnimatePresence>

      {error && (
        <p className="mt-1 text-sm text-red-600 first-letter:capitalize" id={`${name}-description`}>
          {error}
        </p>
      )}
    </div>
  )
}

interface ControlledDateInputProps {
  label: string
  name: string
  rules?: any
  className?: string
  control?: any
  disabled?: boolean
  maxDate?: Date
  minDate?: Date
}

export const ControlledDateInput = ({
  label,
  control,
  name,
  rules,
  className,
  disabled = false,
  maxDate,
  minDate,
}: ControlledDateInputProps) => {
  const {
    field: { onChange, name: inputName, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      ...rules,
      valueAsDate: true,
    },
  })

  return (
    <DateInput
      className={className}
      label={label}
      onChange={onChange}
      error={error?.type}
      value={new Date(value)}
      name={inputName}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
    />
  )
}
