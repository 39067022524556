import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useCallback, useEffect, useMemo } from 'react'
import { XIcon } from '@heroicons/react/outline'

import { useRouter } from 'next/router'
import { Listing } from '@/components/ListingPage/Listing'
import { useSwipeable } from 'react-swipeable'
import { Loading } from '@/components'
import { LISTING_BY_ID } from '@/queries'
import { useLazyQuery, useQuery } from '@apollo/client'

function average(list: any[], property: any): number | null {
  if (list) {
    return list.reduce((a, b) => a + (b[property] ? Number(b[property]) : 0), 0) / list.length || null
  } else {
    return null
  }
}

export const ListingModal = ({
  open,
  setOpen,
  listing,
}: {
  open: boolean
  setOpen: (val: boolean) => any
  listing: string
}) => {
  const { push } = useRouter()
  const [fetchListing, { data, loading, error }] = useLazyQuery(LISTING_BY_ID)

  useEffect(() => {
    if (open) {
      fetchListing({
        variables: {
          id: listing,
        },
      })
    }
  }, [listing, open, fetchListing])

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => setOpen(false),
    trackMouse: false,
    trackTouch: true,
  })

  const averageRating = useMemo(() => average(data?.listingById.reviews, 'rating'), [data])
  const bedrooms = useMemo(() => average(data?.listingById.reviews, 'bedrooms'), [data])
  const bathrooms = useMemo(() => average(data?.listingById.reviews, 'bathrooms'), [data])
  const gasAvgPrice = useMemo(
    () =>
      data?.listingById.reviews?.reduce(
        (a, b) => a + (b?.details?.gasAvgPrice ? Number(b?.details?.gasAvgPrice) : 0),
        0,
      ) / data?.listingById.reviews.length || null,
    [data],
  )
  const hydroAvgPrice = useMemo(
    () =>
      data?.listingById.reviews?.reduce(
        (a, b) => a + (b?.details?.hydroAvgPrice ? Number(b?.details?.hydroAvgPrice) : 0),
        0,
      ) / data?.listingById.reviews.length || null,
    [data],
  )
  const waterAvgPrice = useMemo(
    () =>
      data?.listingById.reviews?.reduce(
        (a, b) => a + (b?.details?.waterAvgPrice ? Number(b?.details?.waterAvgPrice) : 0),
        0,
      ) / data?.listingById.reviews.length || null,
    [data],
  )
  const averageRentPrice = useMemo(
    () =>
      data?.listingById.reviews?.reduce((a, b) => a + (b?.rentPrice ? Number(b?.rentPrice) : 0), 0) /
        data?.listingById.reviews.length || null,
    [data],
  )

  console.log(data)
  return (
    <Transition.Root show={open} as={Fragment} appear={true}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-[32]"
        onClose={() => {
          setOpen(false)
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            appear={true}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#111]   dark:bg-opacity-50 backdrop-blur-sm  bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <div {...handlers} className="fixed bottom-0 top-0 left-0 sm:pr-16 max-w-full flex">
            <Transition.Child
              as={Fragment}
              appear={true}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative w-screen max-w-screen-xl">
                <Transition.Child
                  as={Fragment}
                  appear={true}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-100"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-100"
                  afterLeave={() => push('/explore')}
                >
                  <div className="absolute top-20 xl:right-0 sm:right-2 right-4 pt-4 pr-2 flex sm:-mr-16 sm:pl-4 z-20">
                    <button
                      onClick={() => setOpen(false)}
                      className="rounded-md text-zinc-300 dark:text-zinc-900 hover:text-zinc-500 bg-[#111] dark:bg-snow  e shadow-xl p-1 sm:p-3"
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col pt-36 pb-0 v dark:bg-[#111] bg-snow   shadow-xl overflow-y-scroll">
                  {!loading && data?.listingById ? (
                    <Listing
                      listingData={{
                        ...data?.listingById,
                        averageRating,
                        bedrooms,
                        bathrooms,
                        ratingsAmount: data?.listingById.reviews.length,
                        gasAvgPrice,
                        hydroAvgPrice,
                        waterAvgPrice,
                        waterIncluded: data?.listingById.reviews[0]?.details?.waterIncluded || null,
                        gasIncluded: data?.listingById.reviews[0]?.details?.gasIncluded || null,
                        hydroIncluded: data?.listingById.reviews[0]?.details?.hydroIncluded || null,
                        averageRentPrice,
                      }}
                    />
                  ) : (
                    <div className="m-auto">
                      <Loading />
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
