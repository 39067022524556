import { Menu, Transition } from '@headlessui/react'
import { DesktopComputerIcon, SunIcon, MoonIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'
import clsx from 'clsx'
import { useDarkMode } from '@/hooks/useDarkMode'

export const DarkModeToggle = ({
  background = false,
  position = 'left',
}: {
  background?: boolean
  position?: string
}) => {
  const [mode, toggle, useSystem] = useDarkMode()
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <div className="relative group">
              <Menu.Button
                className={clsx(
                  'inline-flex justify-center w-full text-sm font-medium  rounded-full focus:outline focus:outline-brand  focus:outline-2 focus:outline-offset-4 focus:ring-transparent relative z-[1] group transition ',
                  background
                    ? 'p-2 dark:text-white dark:bg-zinc-800 bg-zinc-50 text-zinc-800 border border-zinc-100 dark:border-zinc-900  hover:bg-opacity-80 dark:shadow-subtle shadow-subtler hover:shadow-2xl'
                    : 'p-1',
                )}
              >
                <span className="sr-only">Options</span>
                {mode ? (
                  <MoonIcon
                    className="w-6 h-6 transition text-zinc-800 dark:text-white group-hover:text-zinc-800 dark:group-hover:text-white"
                    aria-hidden="true"
                  />
                ) : (
                  <SunIcon
                    className="w-6 h-6 transition text-zinc-800 dark:text-white group-hover:text-zinc-800 dark:group-hover:text-white"
                    aria-hidden="true"
                  />
                )}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={clsx(
                  'absolute top-12 w-56 origin-bottom-left  border border-zinc-800 bg-zinc-900 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden p-2',
                  position === 'left' ? 'left-0 ' : '',
                  position === 'right' ? 'right-0 ' : '',
                )}
              >
                <div className="">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => toggle(false)}
                        className={`font-serif cursor-pointer select-none rounded-md p-3 text-white  hover:bg-brand w-full text-left  tracking-tight font-medium leading-none flex items-center text-base group`}
                      >
                        <SunIcon className="w-5 h-5 text-white transition mr-3 relative " aria-hidden="true" />
                        Light
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => toggle(true)}
                        className={`font-serif cursor-pointer select-none rounded-md p-3 text-white  hover:bg-brand w-full text-left  tracking-tight font-medium leading-none flex items-center text-base group`}
                      >
                        <MoonIcon className="w-5 h-5 text-white transition mr-3 relative " aria-hidden="true" />
                        Dark
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={useSystem}
                        className={`font-serif cursor-pointer select-none rounded-md p-3 text-white  hover:bg-brand w-full text-left  tracking-tight font-medium leading-none flex items-center text-base group`}
                      >
                        <DesktopComputerIcon
                          className="w-5 h-5 text-white transition mr-3 relative"
                          aria-hidden="true"
                        />
                        System Setting
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  )
}
