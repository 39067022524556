import { ReactSVG } from 'react-svg'
import Image from 'next/image'
import { ReactNode } from 'react'

export const DetailIcon = ({
  name,
  icon,
  iconClass,
  theme,
  metrics,
  children,
}: {
  name: string
  icon: string | any
  iconClass: string
  theme: string
  metrics?: React.ReactNode
  children: React.ReactNode
}) => {
  return (
    <div className="flex items-start">
      <div className={['rounded-full p-2 dark:shadow-subtle shadow-subtler relative h-8 w-8', theme].join(' ')}>
        <div className="relative -left-2 -top-2.5 z-10 h-8 w-8 -rotate-12">
          <Image src={icon} />
        </div>
      </div>

      <div className="ml-4 mt-2 flex-1">
        <p className=" tracking-tight font-semibold dark:text-zinc-200 text-zinc-800">{children}</p>
        <>{metrics}</>
      </div>
    </div>
  )
}
